import './style.css';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import MenuBar from './Menubar';
import { Dispatch, SetStateAction, useEffect } from 'react';

interface Props {
  isEditMode: boolean;
  content: string;
  handleChangeContent: (content: string) => void;
  isDiscarded?: boolean;
  isContentAvailable?: boolean;
  setCopyText?: Dispatch<SetStateAction<string>>;
}

const Tiptap: React.FC<Props> = ({ isContentAvailable, isEditMode, content, handleChangeContent, isDiscarded, setCopyText }) => {
  const editor = useEditor({
    extensions: [StarterKit, BulletList, ListItem, OrderedList],
    content: content,
    onUpdate: ({ editor }) => {
      handleChangeContent(editor.getHTML());
    },
  });

  useEffect(() => {
    if (editor) {
      editor.commands.setContent(content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContentAvailable]);

  useEffect(() => {
    if (editor) {
      editor.setEditable(isEditMode); // Set editor to be editable or not based on the isEditMode value
    }
  }, [isEditMode, editor]);

  useEffect(() => {
    if (editor && setCopyText) setCopyText(editor.getText({ blockSeparator: '\n\n' }).replace(/\n\n/g, ' '));
  }, [content, editor, setCopyText]);

  return (
    <div>
      {isEditMode ? <MenuBar editor={editor} /> : null}

      <EditorContent
        className={
          isEditMode
            ? 'border-[#7CA6FE] border-[2px] bg-[#235DFF] bg-opacity-5 rounded min-h-[450px] h-fit max-h-[calc(100vh-240px)] md:max-h-[500px] p-4 overflow-auto prose'
            : 'border-primary-gray border-[2px] bg-input-gray rounded min-h-[450px] h-fit max-h-[calc(100vh-240px)] md:max-h-[500px] p-4 overflow-auto prose mt-6'
        }
        editor={editor}
      />
    </div>
  );
};

export default Tiptap;

import { useTranslation } from 'react-i18next';
import { CommonForm, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../../components/CommonForm';
import { CommonRadioGroup, RadioGroupItem } from '../../../components/CommonRadioGroup';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useWatch } from 'react-hook-form';
import { z } from 'zod';
import { enqueueSnackbar } from 'notistack';
import { useAppSelector } from '../../../context/hooks';
import { useEffect, useState } from 'react';
import { selectAccessToken } from '../../../context/selectors/AuthSelectors';
import { useGetAccessToken } from '../../../api/auth/actions/GetAccessToken';
import { useApi } from '../../../api/useApi';
import CommonDiscardDialog from '../../../components/CommonDiscardDialog';
import { CommonText } from '../../../components';
import { ArrowLeft } from 'lucide-react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { getMarketingPreferencesSchema } from '../../../utils/validators/MarketingPreferencesSchema';

const MarketingPreferences = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { t } = useTranslation('common');

  const MarketingPreferencesSchema = getMarketingPreferencesSchema();
  const form = useForm<z.infer<typeof MarketingPreferencesSchema>>({
    resolver: zodResolver(MarketingPreferencesSchema),
  });
  const {
    control,
    reset,
    formState: { dirtyFields },
  } = form;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [doctors, setDoctors] = useState<any>(null);

  useGetAccessToken();
  const accessToken = useAppSelector(selectAccessToken);

  const getDoctor = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response: any = await get(`/doctors/get`);
      if (response?.data?.data) {
        const data = response?.data?.data;

        setDoctors(data);
        reset({
          emailNotifications: data?.emailNotifications ? 'yes' : 'no',
          smsNotifications: data?.smsNotifications ? 'yes' : 'no',
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const { get, put } = useApi();

  const onSubmit = async (data: any) => {
    if (Object.keys(dirtyFields).length === 0) {
      enqueueSnackbar('Form has not changed', { variant: 'warning' });
      return;
    }

    const formatedData = {
      ...doctors,
      emailNotifications: data?.emailNotifications === 'yes' ? true : false,
      smsNotifications: data?.smsNotifications === 'yes' ? true : false,
    };

    const response: any = await put('/doctors/update', formatedData as any);
    if (response?.status === 200 && response?.data.isSuccess && response?.data?.data) {
      enqueueSnackbar('Doctor updated successfully', { variant: 'success' });
    } else {
      const message = response?.data?.messages?.length > 0 ? response?.data?.messages[0] : 'Something went wrong';
      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (accessToken) getDoctor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const handleSubmit = form.handleSubmit(onSubmit);
  const [emailNotifications, smsNotifications] = useWatch({ name: ['emailNotifications', 'smsNotifications'], control });

  return (
    <div className="p-4 md:p-0">
      <div className="flex mt-4 md:mt-0 items-center md:justify-start">
        {isTabletOrMobile && <ArrowLeft onClick={() => navigate('/settings')} />}
        <CommonText className="text-primary-dark font-bold text-2xl m-auto md:m-0" text={t('settings.marketingPreferences')} />
      </div>

      <CommonText
        className="text-primary-dark font-normal text-lg flex mt-10 md:mt-14"
        text={t('settings.marketingPreferencesDescription')}
      />
      <CommonForm {...form}>
        <form onSubmit={handleSubmit}>
          <FormField
            control={control}
            name="emailNotifications"
            render={({ field }) => (
              <div className="flex flex-col gap-2 mt-6">
                <FormItem className="flex flex-row items-center">
                  <FormLabel className="font-semibold w-full md:w-40 pt-2">{t('profileInfo.email')}</FormLabel>
                  <FormControl className="flex-1">
                    <CommonRadioGroup
                      value={emailNotifications}
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex flex-row items-center gap-5"
                    >
                      <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={'yes'} />
                        </FormControl>
                        <FormLabel className="font-medium">{t('yes')}</FormLabel>
                      </FormItem>
                      <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={'no'} />
                        </FormControl>
                        <FormLabel className="font-medium">{t('no')}</FormLabel>
                      </FormItem>
                    </CommonRadioGroup>
                  </FormControl>
                  <div className="flex-1" />
                </FormItem>
                <FormMessage />
              </div>
            )}
          />
          <FormField
            control={control}
            name="smsNotifications"
            render={({ field }) => (
              <div className="flex flex-col gap-2 mt-6">
                <FormItem className="flex flex-row items-center">
                  <FormLabel className="font-semibold w-full md:w-40 mt-2">{t('profileInfo.sms')}</FormLabel>
                  <FormControl className="flex-1">
                    <CommonRadioGroup
                      value={smsNotifications}
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex flex-row items-center gap-5"
                    >
                      <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={'yes'} />
                        </FormControl>
                        <FormLabel className="font-medium">{t('yes')}</FormLabel>
                      </FormItem>
                      <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={'no'} />
                        </FormControl>
                        <FormLabel className="font-medium">{t('no')}</FormLabel>
                      </FormItem>
                    </CommonRadioGroup>
                  </FormControl>
                  <div className="flex-1" />
                </FormItem>
                <FormMessage />
              </div>
            )}
          />
          <div className="flex w-full justify-center z-10">
            <div className="z-10 fixed bottom-0 flex items-center md:relative w-full md:mt-16 bg-white h-24">
              <CommonDiscardDialog
                loading={loading || Object.keys(dirtyFields).length === 0}
                saveButtonName={t('save')}
                primaryDescardedMessage={t('modals.discardAllChanges')}
                secondaryDescardedMessage={t('modals.startOver')}
                isDiscarded={false}
                handleDiscard={async () => {
                  if (Object.keys(dirtyFields).length === 0) {
                    enqueueSnackbar(t('modals.noChangesToDiscard'), { variant: 'warning' });
                    return;
                  }
                  await getDoctor();
                }}
                handleSave={() => handleSubmit}
              />
            </div>
          </div>
        </form>
      </CommonForm>
    </div>
  );
};

export default MarketingPreferences;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useGetAccessToken } from '../../api/auth/actions/GetAccessToken';
import { CommonText } from '../../../src/components/CommonText';
import { CommonProgress } from '../../../src/components/CommonProgress';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrowIcon.svg';
import { ProfileInfo } from '../../components/Onboarding/ProfileInfo';
import { DoctorSpecialty } from '../../components/Onboarding/DoctorSpecialty';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { CommonForm } from '../../components/CommonForm';
import { useApi } from '../../api/useApi';
import { BillingInformation } from '../../components/Onboarding/billingInformation/BillingInformation';
import { useAppDispatch, useAppSelector } from '../../context/hooks';
import { saveSpecialities } from '../../context/slices/ConstantSlice';
import { selectAccessToken } from '../../context/selectors/AuthSelectors';
import { enqueueSnackbar } from 'notistack';
import SetAll from '../../components/Onboarding/SetAll';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { cn } from '../../utils/conditionalClasses';
import ENFlag from '../../assets/icons/unitedKingdom.png';
import GRFlag from '../../assets/icons/greece.png';
import { getOnboardingProfileSchema } from '../../utils/validators/OnboardingProfileSchema';
import { useSearchParams } from 'react-router-dom';

type ProfileDataType = z.infer<any>;
type ProfileResponseDataType = { message: string };

export const OnboardingPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const OnboardingProfileSchema = getOnboardingProfileSchema();
  const form = useForm<z.infer<typeof OnboardingProfileSchema>>({
    resolver: zodResolver(OnboardingProfileSchema),
  });

  let [searchParams, setSearchParams] = useSearchParams();
  const { trigger } = form;

  const dispatch = useAppDispatch();
  useGetAccessToken();
  const accessToken = useAppSelector(selectAccessToken);

  const { post, get } = useApi();

  const screenRef = useRef(null);
  let screenHeight = useRef(window.innerHeight);
  let screenWidth = useRef(window.innerWidth);
  const [isFormTooTall, setIsFormTooTall] = useState(false);
  const [step, setStep] = useState(1);

  const currentLanguage = i18n.language;

  const changeLanguage = (lang: 'en' | 'gr') => {
    if (lang) {
      searchParams.delete('lang');
      setSearchParams(searchParams);
    }
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    const languageFromAuth0 = searchParams.get('lang');
    if (languageFromAuth0) {
      changeLanguage(languageFromAuth0 as 'en' | 'gr');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    const getSpecialities = async () => {
      try {
        const response = await get('/specialties/dropdown', undefined, {
          headers: { 'Accept-Language': i18n.language, LanguageCode: i18n.language },
        });
        const specialities = (response?.data as any)?.data;
        if (specialities?.length > 0) dispatch(saveSpecialities(specialities as any));
      } catch (e) {
        console.error(e);
      }
    };

    if (accessToken) {
      getSpecialities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, i18n?.language]);

  useEffect(() => {
    const handleResize = () => {
      if (screenRef.current) {
        //@ts-ignore
        const formHeight = screenRef.current.scrollHeight;
        screenHeight.current = window.innerHeight;
        screenWidth.current = window.innerWidth;
        setIsFormTooTall(formHeight > screenHeight.current || screenWidth.current < 640);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [step]);

  const goStepBack = useCallback(() => {
    if (step > 1) {
      setStep((prevStep) => prevStep - 1);
    }
  }, [step]);

  const onSubmit = async (data: ProfileDataType) => {
    if (step === 3) {
      const formatedData = {
        ...data,
        birthdate: moment(data?.birthdate).format('YYYY-MM-DD'),
        emailNotifications: data.emailNotifications === 'yes' ? true : false,
        smsNotifications: data.smsNotifications === 'yes' ? true : false,
      };

      const response: any = await post<ProfileDataType, ProfileResponseDataType>('/doctors/signup', formatedData as any);
      if (response?.status === 200 && response?.data.isSuccess && response?.data?.data) {
        enqueueSnackbar('User created successfully', { variant: 'success' });
        setStep((prevStep) => prevStep + 1);
      } else {
        const message = response?.data?.messages?.length > 0 ? response?.data?.messages[0] : 'Something went wrong';
        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    }
  };

  const handleSubmit = form.handleSubmit(onSubmit);

  return (
    <CommonForm {...form}>
      <div className="flex gap-2 justify-end mr-4">
        <div
          onClick={() => changeLanguage('gr')}
          className={cn(
            'flex justify-center items-center w-12',
            currentLanguage === 'gr' ? 'bg-primary-blue rounded-md bg-opacity-10 px-2 cursor-pointer' : 'cursor-pointer'
          )}
        >
          <img className="w-9" src={GRFlag} alt="en flag" />
        </div>
        <div
          onClick={() => changeLanguage('en')}
          className={cn(
            'flex justify-center items-center w-12',
            currentLanguage === 'en' ? 'bg-primary-blue rounded-md bg-opacity-10 px-2 cursor-pointer' : 'cursor-pointer'
          )}
        >
          <img src={ENFlag} className="w-9" alt="en flag" />
        </div>
      </div>
      <form onSubmit={handleSubmit} className={`space-y-8 ${isFormTooTall ? 'pb-20' : ''}`}>
        <div ref={screenRef} className="flex flex-col pt-6 sm:pt-10 items-center relative">
          <div className="flex flex-col gap-8 w-screen max-w-sm">
            {step !== 4 && (
              <div className="flex flex-row gap-10 items-center">
                {step !== 1 && (
                  <button onClick={goStepBack}>
                    <ArrowIcon />
                  </button>
                )}
                <CommonProgress value={step * 25} />
                <CommonText className="text-primary-dark text-xl font-semibold" text={step + '/4'} />
              </div>
            )}
            {step === 1 && (
              <ProfileInfo
                isFormTooTall={isFormTooTall}
                onNext={async () => {
                  const profileRequiredFields = [
                    'title',
                    'smsNotifications',
                    'emailNotifications',
                    'birthdate',
                    'gender',
                    'phoneNumber',
                    'lastName',
                    'firstName',
                  ];

                  const profileFields = profileRequiredFields.map((field) => field as any);
                  const isFormValid = await trigger(profileFields);
                  if (isFormValid) setStep(2);
                }}
              />
            )}
            {step === 2 && <DoctorSpecialty isFormTooTall={isFormTooTall} onNext={() => setStep(3)} />}
            {step === 3 && <BillingInformation isFormTooTall={isFormTooTall} />}
            {step === 4 && <SetAll />}
          </div>
        </div>
      </form>
    </CommonForm>
  );
};

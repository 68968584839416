import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  accessToken: string | null;
  idToken: string | null;
}

const initialState: AuthState = {
  accessToken: null,
  idToken: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    saveIdToken: (state, action: PayloadAction<string>) => {
      state.idToken = action.payload;
    },
    removeTokens: (state) => {
      state = initialState;
    },
  },
});

export const { saveAccessToken, saveIdToken, removeTokens } = authSlice.actions;
export const authReducer = authSlice.reducer;

import { CommonButton } from '../../../src/components/CommonButton';
import { CommonText } from '../../../src/components/CommonText';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactComponent as InfoIcon } from '../../assets/icons/infoIcon.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ENFlag from '../../assets/icons/unitedKingdom.png';
import GRFlag from '../../assets/icons/greece.png';
import { cn } from '../../utils/conditionalClasses';

export const WelcomePage: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const [showInfo, setShowInfo] = useState(false);
  const { t, i18n } = useTranslation('common');
  const currentLanguage = i18n.language;
  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: `/onboarding?lang=${currentLanguage}`,
      },
      authorizationParams: {
        prompt: 'login',
        screen_hint: 'signup',
        ui_locales: currentLanguage === 'gr' ? 'el' : 'en',
      },
    });
  };

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: `/home/voiceRecord/?lang=${currentLanguage}`,
      },
      authorizationParams: {
        prompt: 'login',
        ui_locales: currentLanguage === 'gr' ? 'el' : 'en',
      },
    });
  };

  const showUserInfo = () => {
    setShowInfo((prev) => !prev);
  };

  const changeLanguage = (lang: 'en' | 'gr') => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="flex flex-col gap-16 pt-14 pb-8 h-screen items-center justify-between sm:justify-normal">
      <div className="w-full flex flex-col justify-center ">
        <div className="flex gap-2 justify-end mr-4">
          <div
            onClick={() => changeLanguage('gr')}
            className={cn(
              'flex justify-center items-center',
              currentLanguage === 'gr' ? 'bg-primary-blue rounded-md bg-opacity-10 px-2 cursor-pointer' : 'cursor-pointer'
            )}
          >
            <img className="w-9" src={GRFlag} alt="en flag" />
          </div>
          <div
            onClick={() => changeLanguage('en')}
            className={
              (cn('flex justify-center items-center'),
              currentLanguage === 'en' ? 'bg-primary-blue rounded-md bg-opacity-10 px-2 cursor-pointer' : 'cursor-pointer')
            }
          >
            <img src={ENFlag} className="w-9" alt="en flag" />
          </div>
        </div>
        <CommonText className="text-primary-blue font-bold text-5xl text-center" text="PulsePad.ai" />
      </div>

      <div className="flex flex-col gap-6 items-center px-2 text-center">
        <CommonText className="text-primary-dark font-bold text-3xl" text={t('welcome.title')} />
        <div className="w-56">
          <CommonText
            className="text-secondary-dark font-normal text-lg text-center"
            text={
              <p>
                {t('welcome.subtitle1')}
                <InfoIcon className="inline-block align-top" onClick={showUserInfo} /> {t('welcome.subtitle2')}
              </p>
            }
          />
        </div>
        {showInfo && (
          <div className="bg-secondary-blue flex flex-row w-screen max-w-sm p-3 gap-3 rounded-md">
            <div>
              <InfoIcon onClick={showUserInfo} />
            </div>
            <CommonText className="text-primary-blue text-left text-sm" text={t('welcome.soap')} />
          </div>
        )}
      </div>
      <div className="flex flex-col items-center gap-5">
        <CommonButton onClick={handleSignUp}>{t('welcome.signUp')}</CommonButton>
        <CommonButton variant="secondary" onClick={handleLogin}>
          {t('welcome.logIn')}
        </CommonButton>
      </div>
      <div className="w-screen max-w-96">
        <CommonText
          className="text-secondary-dark text-center text-sm"
          text={
            <p>
              {t('welcome.byContinuing')} <span className="text-primary-blue">{t('welcome.termsOfService')}</span>{' '}
              {t('welcome.acknowledge')} <span className="text-primary-blue">{t('welcome.privacyPolicy')}</span>
            </p>
          }
        />
      </div>
    </div>
  );
};

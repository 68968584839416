import * as React from 'react';
import { cn } from '../utils/conditionalClasses';
import { useFormField } from './CommonForm';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const CommonInput = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
  const { error } = useFormField();

  return (
    <input
      type={type}
      className={cn(
        'flex h-16 w-full rounded-xl bg-input-gray px-5 text-lg text-primary-dark file:border-0 file:bg-transparent file:text-sm file:font-medium font-medium placeholder:font-normal placeholder:text-secondary-dark focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
        className,
        `${error ? 'border-2 border-primary-error' : ''}`
      )}
      ref={ref}
      {...props}
    />
  );
});
CommonInput.displayName = 'Input';

export { CommonInput };

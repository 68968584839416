import React from 'react';
import { CommonText } from '../../CommonText';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../CommonForm';
import { CommonInput } from '../../CommonInput';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const DoctorClinics: React.FC = () => {
  const { control } = useFormContext();
  const { t } = useTranslation('common');
  return (
    <div>
      <div className="mt-6 flex justify-between items-center">
        <CommonText text={t('settings.billingInformation')} className="w-[40%] text-sm font-semibold text-secondary-gray" />
        <div className="border-b-[1px] border-b-secondary-gray w-[60%]"></div>
      </div>
      <div className="mt-6">
        <FormField
          control={control}
          name={`clinics.${0}.name`}
          render={({ field }) => (
            <FormItem className="mt-4">
              <FormLabel className="font-semibold">{t('profileInfo.companyName')}</FormLabel>
              <FormControl>
                <CommonInput placeholder={t('profileInfo.companyName')} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name={`clinics.${0}.registrationNumber`}
          render={({ field }) => (
            <FormItem className="mt-4">
              <FormLabel className="font-semibold">{t('profileInfo.regNumber')}</FormLabel>
              <FormControl>
                <CommonInput placeholder={t('profileInfo.regNumber')} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name={`clinics.${0}.vatReg`}
          render={({ field }) => (
            <FormItem className="mt-6">
              <FormLabel className="font-semibold">{t('profileInfo.vatReg')}</FormLabel>
              <FormControl>
                <CommonInput placeholder={t('profileInfo.vatReg')} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name={`clinics.${0}.address`}
          render={({ field }) => (
            <FormItem className="mt-6">
              <FormLabel className="font-semibold">{t('profileInfo.companyAddress')}</FormLabel>
              <FormControl>
                <CommonInput placeholder={t('profileInfo.companyAddress')} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

export default DoctorClinics;

import { AppState, Auth0Provider, useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { SnackbarProvider } from 'notistack';
import { FunctionComponent, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { WelcomePage } from './auth/WelcomePage';
import { Provider } from 'react-redux';
import { store } from '../context/store';
import { OnboardingPage } from './auth/OnboardingPage';
import Layout from '../components/layout/Layout';
import AudioContainer from './newRecording/AudioContainer';
import UploadAudio from './newRecording/UploadAudio';
import RecordAudio from './newRecording/RecordAudio';
import ReportDetails from './report/ReportDetails';
import ReportContent from './report/ReportContent';
import Library from './library/Library';
import Settings from './settings/Settings';
import MyProfile from './settings/sections/MyProfile';
import MarketingPreferences from './settings/sections/MarketingPreferences';
import BillingInformation from './settings/sections/BillingInformation';
import { useGetAccessToken } from '../api/auth/actions/GetAccessToken';
import { useAppSelector } from '../context/hooks';
import { selectAccessToken } from '../context/selectors/AuthSelectors';
import { useApi } from '../api/useApi';
import { useTranslation } from 'react-i18next';

const ProtectedRoute = ({ component, ...args }: { component: FunctionComponent }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const { i18n } = useTranslation();
  const language = i18n.language;

  useGetAccessToken();
  const accessToken = useAppSelector(selectAccessToken);

  const { get } = useApi();

  const getDoctor = async () => {
    try {
      const response: any = await get(`/doctors/get`);
      if (!response?.data?.data) {
        window.location.replace(`/onboarding?lang=${language}`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (accessToken) {
      getDoctor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  if (isLoading) {
    return <div></div>;
  }

  if (!isAuthenticated) {
    window.location.replace('/welcome');
    return null;
  }

  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

const AuthProtectedRoute = ({ component, ...args }: { component: FunctionComponent }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div></div>;
  }

  if (!isAuthenticated) {
    window.location.replace('/welcome');
    return null;
  }

  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

const Auth0ProviderWithRedirectCallback = ({ children }: { children: React.ReactNode }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE_URL;

  const navigate = useNavigate();
  const onRedirectCallback = (appState?: AppState) => {
    console.log({ appState });
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider
      //@ts-ignore
      domain={domain}
      //@ts-ignore
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience,
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <SnackbarProvider />
      <BrowserRouter>
        <Auth0ProviderWithRedirectCallback>
          <Routes>
            <Route path="/" element={<Navigate replace to="/home/voiceRecord" />} />
            <Route path="/onboarding" element={<AuthProtectedRoute component={OnboardingPage} />} />
            <Route path="/allSet" element={<ProtectedRoute component={() => <div>All Set</div>} />} />
            <Route path="/welcome" element={<WelcomePage />} />
            <Route
              path="/home/voiceRecord"
              element={
                <ProtectedRoute
                  component={() => (
                    <Layout>
                      <AudioContainer>
                        <RecordAudio />
                      </AudioContainer>
                    </Layout>
                  )}
                />
              }
            />
            <Route
              path="/home/uploadRecord"
              element={
                <ProtectedRoute
                  component={() => (
                    <Layout>
                      <AudioContainer>
                        <UploadAudio />
                      </AudioContainer>
                    </Layout>
                  )}
                />
              }
            />
            <Route
              path="/home/voiceRecord/reportDetails"
              element={
                <ProtectedRoute
                  component={() => (
                    <Layout>
                      <AudioContainer>
                        <ReportDetails />
                      </AudioContainer>
                    </Layout>
                  )}
                />
              }
            />
            <Route
              path="/home/uploadRecord/reportDetails"
              element={
                <ProtectedRoute
                  component={() => (
                    <Layout>
                      <AudioContainer>
                        <ReportDetails />
                      </AudioContainer>
                    </Layout>
                  )}
                />
              }
            />
            <Route
              path={`/library/report/:id`}
              element={
                <ProtectedRoute
                  component={() => (
                    <Layout>
                      <AudioContainer>
                        <ReportContent />
                      </AudioContainer>
                    </Layout>
                  )}
                />
              }
            />

            <Route
              path="/library"
              element={
                <ProtectedRoute
                  component={() => (
                    <Layout>
                      <Library />
                    </Layout>
                  )}
                />
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute
                  component={() => (
                    <Layout>
                      <Settings />
                    </Layout>
                  )}
                />
              }
            />
            <Route
              path="/settings/profile"
              element={
                <ProtectedRoute
                  component={() => (
                    <Layout>
                      <MyProfile />
                    </Layout>
                  )}
                />
              }
            />
            <Route
              path="/settings/marketingPreferences"
              element={
                <ProtectedRoute
                  component={() => (
                    <Layout>
                      <MarketingPreferences />
                    </Layout>
                  )}
                />
              }
            />
            <Route
              path="/settings/billingInformation"
              element={
                <ProtectedRoute
                  component={() => (
                    <Layout>
                      <BillingInformation />
                    </Layout>
                  )}
                />
              }
            />
          </Routes>
        </Auth0ProviderWithRedirectCallback>
      </BrowserRouter>
    </Provider>
  );
}

export default App;

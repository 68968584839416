import { CommonForm, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../../components/CommonForm';
import { CommonInput } from '../../../components/CommonInput';
import { CommonPopover, PopoverContent, PopoverTrigger } from '../../../components/CommonPopover';
import { CommonSelect, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../components/CommonSelect';
import { CommonYearCalendar } from '../../../components/CommonYearCalendar';
import { cn } from '../../../utils/conditionalClasses';
import { zodResolver } from '@hookform/resolvers/zod';
import { ArrowLeft, CalendarIcon } from 'lucide-react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { format } from 'date-fns';
import { useGetAccessToken } from '../../../api/auth/actions/GetAccessToken';
import { useApi } from '../../../api/useApi';
import { useEffect, useState } from 'react';
import { CommonButton, CommonText } from '../../../components';
import { useAppSelector } from '../../../context/hooks';
import { selectAccessToken } from '../../../context/selectors/AuthSelectors';
import { enqueueSnackbar } from 'notistack';
import moment from 'moment';
import CommonDiscardDialog from '../../../components/CommonDiscardDialog';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { getMyProfileSchema } from '../../../utils/validators/MyProfileSchema';

const MyProfile = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { t } = useTranslation('common');
  const MyProfileSchema = getMyProfileSchema();
  const form = useForm<z.infer<typeof MyProfileSchema>>({
    resolver: zodResolver(MyProfileSchema),
  });
  const {
    control,
    setValue,
    reset,
    formState: { dirtyFields },
  } = form;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [doctors, setDoctors] = useState<any>(null);

  useGetAccessToken();
  const accessToken = useAppSelector(selectAccessToken);

  const getDoctor = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response: any = await get(`/doctors/get`);
      if (response?.data?.data) {
        const data = response?.data?.data;
        setDoctors(data);
        reset({
          firstName: data?.firstName,
          email: data?.email,
          lastName: data?.lastName,
          gender: data?.gender,
          birthdate: `${data?.birthdate}` as any,
          clinics:
            data?.clinics?.length > 0
              ? [
                  {
                    vatReg: data.clinics[0]?.vatReg,
                    registrationNumber: data.clinics[0]?.registrationNumber,
                    address: data.clinics[0]?.address,
                  },
                ]
              : null,
          phoneNumber: data?.phoneNumber,
          title: data?.title,
        });
        setValue('birthdate', new Date(data?.birthdate), { shouldDirty: false });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const { get, put } = useApi();

  const onSubmit = async (data: any) => {
    if (Object.keys(dirtyFields).length === 0) {
      enqueueSnackbar('Form has not changed', { variant: 'warning' });
      return;
    }
    setLoading(true);

    let formatedData = {
      ...data,
      birthdate: moment(data?.birthdate).format('YYYY-MM-DD'),
      id: doctors?.id,
      emailNotifications: doctors?.emailNotifications,
      smsNotifications: doctors?.smsNotifications,
      nickname: doctors?.nickname,
    };

    if (data?.clinics && doctors.clinics)
      formatedData.clinics = [{ ...data?.clinics[0], id: doctors?.clinics[0]?.id, name: doctors?.clinics[0]?.name }];
    else formatedData.clinics = null;

    const response: any = await put('/doctors/update', formatedData as any);
    if (response?.status === 200 && response?.data.isSuccess && response?.data?.data) {
      enqueueSnackbar('Doctor upadated successfully', { variant: 'success' });
    } else {
      const message = response?.data?.messages?.length > 0 ? response?.data?.messages[0] : 'Something went wrong';
      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (accessToken) getDoctor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const handleSubmit = form.handleSubmit(onSubmit);

  const [birthdate, title, gender] = useWatch({ name: ['birthdate', 'title', 'gender'], control });

  return (
    <div className={'p-4 pb-[100px] md:p-0 md:pb-24'}>
      <div>
        <CommonForm {...form}>
          <div className="flex mt-4 md:mt-0 items-center md:justify-start">
            {isTabletOrMobile && <ArrowLeft onClick={() => navigate('/settings')} />}
            <CommonText className="text-primary-dark font-bold text-2xl m-auto md:m-0" text={t('settings.myProfile')} />
          </div>

          <form onSubmit={handleSubmit}>
            <div className="mt-6 flex gap-2 items-center md:mt-14">
              <CommonText
                className="font-customFont font-semibold text-sm text-third-gray w-[40%] md:w-[12%]"
                text={t('settings.doctorInformation')}
              />
              <div className="border-t-[1px] border-primary-gray w-[60%] md:w-[88%]"></div>
            </div>
            <div className="flex flex-col md:flex-row md:gap-2 lg:gap-8 ">
              <div className="mt-4 md:mt-8 w-full md:w-[20%] lg:w-[10%]">
                <FormField
                  control={control}
                  name="title"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="font-semibold">{t('profileInfo.title')}</FormLabel>
                      <CommonSelect value={title} onValueChange={field.onChange} defaultValue={field.value}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder={t('profileInfo.selectTitle')} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="Dr">{t('profileInfo.dr')}</SelectItem>
                          <SelectItem value="Drs">{t('profileInfo.drs')}</SelectItem>
                        </SelectContent>
                      </CommonSelect>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="mt-8 w-full md:w-[40%] lg:w-[45%]">
                <FormField
                  control={control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="font-semibold">{t('profileInfo.firstName')}</FormLabel>
                      <FormControl>
                        <CommonInput placeholder={'John'} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="mt-8 w-full md:w-[40%] lg:w-[45%]">
                <FormField
                  control={control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="font-semibold">{t('profileInfo.lastName')}</FormLabel>
                      <FormControl>
                        <CommonInput placeholder={'Doe'} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:gap-2 lg:gap-8">
              <div className="mt-8 w-full md:w-[50%] lg:w-[50%]">
                <FormField
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="font-semibold">{t('settings.email')}</FormLabel>
                      <FormControl>
                        <CommonInput placeholder={'John Doe'} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="mt-8 w-full md:w-[50%] lg:w-[50%]">
                <FormField
                  control={control}
                  name="phoneNumber"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="font-semibold">{t('profileInfo.phoneNumber')}</FormLabel>
                      <FormControl>
                        <CommonInput placeholder={t('profileInfo.phoneNumber')} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:gap-2 lg:gap-8">
              <div className="mt-8 w-full md:w-[50%] lg:w-[50%]">
                <FormField
                  control={control}
                  name="gender"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="font-semibold">{t('profileInfo.gender')}</FormLabel>
                      <CommonSelect value={gender} onValueChange={field.onChange} defaultValue={field.value}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder={t('profileInfo.selectGender')} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="Female">{t('profileInfo.female')}</SelectItem>
                          <SelectItem value="Male">{t('profileInfo.male')}</SelectItem>
                        </SelectContent>
                      </CommonSelect>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="mt-8 md:mt-10 w-full md:w-[50%] lg:w-[50%]">
                <FormField
                  control={control}
                  name="birthdate"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel className="font-semibold">{t('profileInfo.birthdate')}</FormLabel>
                      {/* @ts-ignore */}
                      <CommonPopover>
                        <PopoverTrigger asChild>
                          <CommonButton
                            variant={'outline'}
                            className={cn(
                              'w-full max-w-[100%] h-16 justify-start rounded-md text-secondary-dark text-left text-lg font-medium font-customFont bg-input-gray pl-4'
                            )}
                          >
                            <CalendarIcon className="mr-2 h-5 w-5" />
                            {birthdate ? format(birthdate, 'PPP') : <span>{t('pickADate')}</span>}
                          </CommonButton>
                        </PopoverTrigger>
                        <PopoverContent align="start" className=" w-auto p-4 border-none bg-white">
                          <CommonYearCalendar
                            mode="single"
                            captionLayout="dropdown-buttons"
                            selected={new Date(field?.value)}
                            onSelect={(e) => setValue('birthdate', e as any)}
                            fromYear={1960}
                            toYear={2050}
                          />
                        </PopoverContent>
                      </CommonPopover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            {doctors?.clinics?.length > 0 && (
              <>
                <div className="mt-6 flex gap-2 items-center">
                  <CommonText
                    className="font-customFont font-semibold text-sm text-third-gray w-[40%] md:w-[12%]"
                    text={t('profileInfo.companyInformation')}
                  />
                  <div className="border-t-[1px] border-primary-gray w-[60%] md:w-[88%]"></div>
                </div>{' '}
                <div className="flex flex-col md:flex-row md:gap-2 lg:gap-8">
                  <div className="mt-6 w-full md:w-[33%] lg:w-[33%]">
                    <FormField
                      control={control}
                      name={`clinics.${0}.registrationNumber`}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="font-semibold">{t('profileInfo.regNumber')}</FormLabel>
                          <FormControl>
                            <CommonInput placeholder={t('profileInfo.regNumber')} {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <div className="mt-6 w-full md:w-[33%] lg:w-[33%]">
                    <FormField
                      control={control}
                      name={`clinics.${0}.vatReg`}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="font-semibold">{t('profileInfo.vatReg')}</FormLabel>
                          <FormControl>
                            <CommonInput placeholder={t('profileInfo.vatReg')} {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <div className="mt-6 w-full md:w-[33%] lg:w-[33%]">
                    <FormField
                      control={control}
                      name={`clinics.${0}.address`}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="font-semibold">{t('profileInfo.companyAddress')}</FormLabel>
                          <FormControl>
                            <CommonInput placeholder={t('profileInfo.companyAddress')} {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="flex w-full justify-center z-10">
              <div className="z-10 fixed bottom-0 flex items-center md:relative w-full md:mt-16 bg-white h-24">
                <CommonDiscardDialog
                  loading={loading || Object.keys(dirtyFields).length === 0}
                  saveButtonName={t('save')}
                  primaryDescardedMessage={t('modals.discardAllChanges')}
                  secondaryDescardedMessage={t('modals.startOver')}
                  isDiscarded={false}
                  handleDiscard={async () => {
                    if (Object.keys(dirtyFields).length === 0) {
                      enqueueSnackbar(t('modals.noChangesToDiscard'), { variant: 'warning' });
                      return;
                    }
                    await getDoctor();
                  }}
                  handleSave={() => handleSubmit}
                />
              </div>
            </div>
          </form>
        </CommonForm>
      </div>
    </div>
  );
};

export default MyProfile;

import { CommonText } from '../CommonText';
import { CommonButton } from '../CommonButton';
import { useNavigate } from 'react-router-dom';
import EmptyGridIcon from '../../assets/icons/emptyGrid.svg';
import { useTranslation } from 'react-i18next';

const EmptyLibrary = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  return (
    <div className="h-full w-full flex justify-center items-center flex-col gap-12">
      <img src={EmptyGridIcon} alt="empty grid" />
      <div className="flex justify-center items-center flex-col gap-4">
        <CommonText className="font-bold text-2xl font-customFont" text={t('library.nothingYet')} />
        <CommonText className="font-normal text-sm text-third-gray font-customFont" text={t('library.startRecordingLibrary')} />
        <CommonButton onClick={() => navigate('/home/voiceRecord')} className="w-40" variant={'default'}>
          {t('library.recordNowLibrary')}
        </CommonButton>
      </div>
    </div>
  );
};

export default EmptyLibrary;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useState } from 'react';
import { Library, Mic, Settings } from 'lucide-react';
import { CommonText } from '../CommonText';
import { useLocation, useSearchParams } from 'react-router-dom';
import SubMenu from '../Nav/SubMenu';
import Header from '../Nav/Header';
import FooterMenu from '../Nav/FooterMenu';
import Menu from '../Nav/Menu';
import { useMediaQuery } from 'react-responsive';
import MobileMenu from '../Nav/MobileMenu';
import { locale } from '../../i18n';
import { useTranslation } from 'react-i18next';

type Props = {
  children: any;
};

type SubMenuType = {
  redirectTo: string;
  name: string;
};

type MenuType = {
  navId: string;
  icon: ReactElement;
  name: string;
  url: string;
  subMenus?: SubMenuType[] | null;
  parentNavigate?: boolean | null;
};

const menus = () => [
  {
    navId: 'nav_1',
    icon: <Mic width={'20px'} />,
    name: locale.t('nav.newRecordings'),
    url: '/home',
    subMenus: [
      { redirectTo: '/voiceRecord', name: locale.t('nav.voiceRecorder') },
      { redirectTo: '/uploadRecord', name: locale.t('nav.uploadRecording') },
    ],
  },
  {
    navId: 'nav_2',
    icon: <Library width={'20px'} />,
    name: locale.t('nav.myLibrary'),
    url: '/library',
    parentNavigate: true,
  },
  {
    navId: 'nav_3',
    icon: <Settings width={'20px'} />,
    name: locale.t('nav.settings'),
    url: '/settings',
    parentNavigate: true,
  },
];

export const Layout: React.FC<Props> = ({ children }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { pathname } = useLocation();
  const [openedMenu, setOpenedMenu] = useState<string | null>(pathname.includes('/home') ? 'nav_1' : null);
  let [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();
  const menuList = menus();

  const toggleSubMenu = (navId: string) => {
    setOpenedMenu((prevState) => (prevState === navId ? null : navId));
  };

  useEffect(() => {
    for (let i = 0; i < menuList.length; i++) {
      if (pathname.includes(menuList[i].url)) {
        setOpenedMenu(menuList[i].navId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const lang = searchParams.get('lang');
    if (lang) {
      searchParams.delete('lang');
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <div className="flex h-screen bg-gray-100 border-none">
      {!isTabletOrMobile && (
        <div className="w-[276px] bg-white border-r-[1px] border-r-primary-gray transition-all duration-300 ease-in-out ">
          <CommonText className="text-primary-dark font-bold text-5xl flex justify-center mt-4" text="PulsePad.ai" />
          <div className="flex flex-col mt-8 " style={{ height: 'calc(100vh - 100px)' }}>
            <nav className="flex flex-col px-6 h-[-webkit-fill-available] justify-between">
              <div>
                {menuList.map((menu: MenuType, index) => (
                  <div key={index}>
                    <Menu
                      index={index}
                      menuIcon={menu?.icon}
                      menuName={menu.name}
                      hasSubMenu={menu?.subMenus && menu?.subMenus?.length > 0}
                      menuNavId={menu.navId}
                      openedMenu={openedMenu}
                      toggleSubMenu={toggleSubMenu}
                      parentNavigate={menu?.parentNavigate}
                      menuUrl={menu.url}
                    />
                    <div
                      className={`${menu?.subMenus && menu?.subMenus?.length > 0 && openedMenu === menu.navId ? 'block' : 'hidden'} pl-8 transition-all duration-300 ease-in-out cursor-pointer `}
                    >
                      {menu?.subMenus?.map((subMenu: SubMenuType, index: number) => {
                        return (
                          <SubMenu
                            index={index}
                            menuNavId={menu.navId}
                            menuUrl={menu.url}
                            redirectToForSubMenu={subMenu.redirectTo}
                            nameSubMenu={subMenu.name}
                            setOpenedMenu={setOpenedMenu}
                          />
                        );
                      })}
                    </div>
                  </div>
                ))}
              </div>
              <FooterMenu toggleSubMenu={toggleSubMenu} />
            </nav>
          </div>
        </div>
      )}
      <div className="flex-1 flex flex-col">
        {!isTabletOrMobile && <Header />}
        <main className="flex-1 md:p-6 overflow-auto h-[calc(100vh - 88px)]">{children}</main>
        {isTabletOrMobile && <MobileMenu />}
      </div>
    </div>
  );
};

export default Layout;

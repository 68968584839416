import React, { ChangeEventHandler, Dispatch, SetStateAction } from 'react';
import { CommonText } from '../CommonText';
import { ArrowLeft, CalendarDays, EllipsisVertical, Search, Trash } from 'lucide-react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { CommonPopover, PopoverContent, PopoverTrigger } from '../CommonPopover';
import { cn } from '../../utils/conditionalClasses';

const HeaderLibrary: React.FC<{
  showElement: boolean;
  handleDeleteReports: any;
  allowSelecting: boolean;
  searchValue: string;
  setSelectedRows: Dispatch<SetStateAction<string[] | null>>;
  setAllowSelecting: Dispatch<SetStateAction<boolean>>;
  handleInputChange: ChangeEventHandler<HTMLInputElement>;
  setShowDeleteOption: Dispatch<SetStateAction<boolean>>;
  showDeleteOption?: boolean;
}> = ({
  showElement,
  searchValue = '',
  handleInputChange,
  setShowDeleteOption,
  showDeleteOption,
  setAllowSelecting,
  allowSelecting,
  handleDeleteReports,
  setSelectedRows,
}) => {
  const { t } = useTranslation('common');
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <>
      <div className="flex justify-between relative items-center">
        {isTabletOrMobile && <ArrowLeft />}
        <CommonText className="text-primary-dark font-bold text-2xl flex " text={t('library.myLibrary')} />

        <div className="flex gap-4 justify-between items-center">
          <div
            onClick={() => {
              if (allowSelecting) {
                setSelectedRows(null);
              }
              setAllowSelecting(!allowSelecting);
            }}
            className={cn(
              'flex cursor-pointer h-8 w-fit px-4 border-[1px] items-center rounded-full gap-2',
              allowSelecting ? 'bg-primary-gray border-primary-gray' : 'bg-primary-blue border-primary-blue'
            )}
          >
            {allowSelecting ? (
              <CommonText className="text-white font-semibold text-sm" text={t('unselect')} />
            ) : (
              <CommonText className="text-white font-semibold text-sm" text={t('select')} />
            )}
          </div>
          <CommonPopover open={showDeleteOption} onOpenChange={setShowDeleteOption}>
            <PopoverTrigger asChild>
              <EllipsisVertical className="cursor-pointer" />
            </PopoverTrigger>

            <PopoverContent className="w-60 relative border-none right-6 bg-white">
              <div className="grid gap-4">
                <div onClick={handleDeleteReports} className="space-y-2 flex gap-3 cursor-pointer">
                  <Trash className="text-primary-error items-center" />
                  <CommonText className="text-primary-error font-semibold text-lg flex !mt-0" text={t('deleteSelected')} />
                </div>
              </div>
            </PopoverContent>
          </CommonPopover>
        </div>
      </div>
      {showElement && (
        <div className="relative w-full mt-6">
          <input
            onChange={handleInputChange}
            value={searchValue}
            type="text"
            placeholder={t('library.searchPatient')}
            className="h-16 w-full pl-10 border border-primary-gray rounded-xl bg-input-gray px-5 text-lg text-primary-dark font-medium placeholder:font-normal placeholder:text-secondary-dark disabled:cursor-not-allowed disabled:opacity-50"
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-secondary-dark" />
        </div>
      )}
      {showElement && (
        <div className="flex gap-4 justify-between items-center mt-5">
          <div className="flex cursor-pointer h-8 w-fit px-4 border-[1px] border-primary-blue items-center rounded-full gap-2">
            <CalendarDays className="w-4 h-4 text-primary-blue" />
            <CommonText className="text-primary-blue font-semibold text-sm" text={t('library.sortByDate')} />
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderLibrary;

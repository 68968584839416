import {
  CommonDialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
} from '../components/CommonDialog';
import React from 'react';
import { CommonButton } from './CommonButton';

interface Props {
  isOpen: boolean;
  handleDiscard: () => void;
  handleSave?: () => void;
}

const MicrophoneDialog: React.FC<Props> = ({ handleDiscard, isOpen, handleSave }) => {
  return (
    <div className="flex justify-center gap-4 items-center w-full">
      <CommonDialog open={isOpen}>
        <DialogOverlay>
          <DialogContent className="sm:max-w-[425px] rounded-tl-2xl rounded-tr-2xl border-none bg-white top-full -mt-[125px] md:top-1/2 md:mt-0">
            <DialogHeader>
              <DialogTitle className="font-customFont flex justify-center font-bold text-2xl text-primary-blue">
                Unable to access microphone?
              </DialogTitle>
            </DialogHeader>
            <div className="font-customFont py-4 flex justify-center font-medium text-xl text-center text-primary-dark">
              Please allow PulsePad to access your microphone from device settings.
            </div>
            <DialogFooter className="flex !justify-center !flex-row items-center gap-6">
              <DialogClose asChild>
                <CommonButton variant={'secondary'} className="font-customFont w-36 md:w-40 h-12 font-bold" onClick={handleDiscard}>
                  Don't Allow
                </CommonButton>
              </DialogClose>
              <DialogClose asChild>
                <CommonButton variant={'default'} className="font-customFont w-36 md:w-40 h-12 font-bold" onClick={handleSave}>
                  Request Access
                </CommonButton>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </DialogOverlay>
      </CommonDialog>
    </div>
  );
};

export default MicrophoneDialog;

import { useTranslation } from 'react-i18next';
import { CommonPopover, PopoverContent, PopoverTrigger } from '../../components/CommonPopover';
import { CommonText } from '../../components/CommonText';
import { EllipsisVertical, FilePenLine, PencilLine, RefreshCw, Trash } from 'lucide-react';
import React, { Dispatch, SetStateAction } from 'react';

interface Props {
  setIsOpenDeleteDialog: Dispatch<SetStateAction<boolean>>;
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
  setIsGenerating: Dispatch<SetStateAction<boolean>>;
  isEditMode: boolean;
  patientName: string | null;
}

const ReportHeader: React.FC<Props> = ({ patientName = '', setIsOpenDeleteDialog, setIsEditMode, isEditMode, setIsGenerating }) => {
  const { t } = useTranslation('common');
  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex gap-2 items-center">
          <CommonText className="text-primary-dark font-bold text-2xl flex " text={patientName || ''} />
          <PencilLine className="text-primary-dark cursor-pointer" />
        </div>
        <CommonPopover>
          <PopoverTrigger asChild>
            <EllipsisVertical className="cursor-pointer" />
          </PopoverTrigger>
          <PopoverContent className="w-60 relative border-none right-6 bg-white">
            <div className="grid gap-4">
              <div onClick={() => setIsGenerating(true)} className="space-y-2 flex gap-3 items-center cursor-pointer">
                <RefreshCw className="text-primary-dark" />
                <CommonText className="text-primary-dark font-semibold text-lg flex !mt-0 " text={t('modals.regenerate')} />
              </div>
              <div className="border-t-[1px] border-t-secondary-gray w-ful border-opacity-30"></div>
              <div onClick={() => setIsEditMode(true)} className="space-y-2 flex gap-3 cursor-pointer">
                <FilePenLine className="text-primary-dark items-center" />
                <CommonText className="text-primary-dark font-semibold text-lg flex !mt-0" text={t('modals.editReportDetails')} />
              </div>
              <div className="border-t-[1px] border-t-secondary-gray w-ful border-opacity-30"></div>
              <div onClick={() => setIsOpenDeleteDialog(true)} className="space-y-2 flex gap-3 cursor-pointer">
                <Trash className="text-primary-error items-center" />
                <CommonText className="text-primary-error font-semibold text-lg flex !mt-0" text={t('modals.delete')} />
              </div>
            </div>
          </PopoverContent>
        </CommonPopover>
      </div>
      <div className="flex justify-between items-center mt-4 md:mt-12">
        <CommonText className="font-semibold text-xl font-customFont" text={t('modals.report')} />
        <div
          onClick={() => setIsEditMode((prevState) => !prevState)}
          className="flex cursor-pointer h-8 w-fit px-4 border-[1px] border-primary-blue bg-primary-blue items-center rounded-full gap-2"
        >
          <CommonText className="text-white font-semibold text-sm" text={isEditMode ? t('report.finishEditing') : t('report.editReport')} />
          <PencilLine width={'14px'} height={'14px'} className="text-white cursor-pointer text-base" />
        </div>
      </div>
    </>
  );
};

export default ReportHeader;

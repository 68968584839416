import {
  CommonDialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
} from '../components/CommonDialog';
import React from 'react';
import { CommonButton } from './CommonButton';
import { TriangleAlert } from 'lucide-react';

interface Props {
  isOpen: boolean;
  handleDiscard: () => void;
  handleFixMic?: () => void;
}

const CommonMicrophoneIssueModal: React.FC<Props> = ({ handleDiscard, isOpen, handleFixMic }) => {
  return (
    <div className="flex justify-center gap-4 items-center w-full">
      <CommonDialog open={isOpen}>
        <DialogOverlay>
          <DialogContent className="sm:max-w-[425px] rounded-tl-2xl rounded-tr-2xl border-none bg-white top-full -mt-[154px] md:top-1/2 md:mt-0">
            <DialogHeader>
              <DialogTitle className="font-customFont flex justify-center font-bold text-2xl text-primary-dark flex-col items-center">
                <div className="w-16 h-16 rounded-full bg-primary-error flex items-center justify-center">
                  <TriangleAlert className="text-white" />
                </div>
                <div className="pt-2">Microphone Issues</div>
              </DialogTitle>
            </DialogHeader>
            <div className="font-customFont pb-2 flex justify-center font-medium text-xl text-center text-primary-dark">
              We have detected silence while you are recording, please continue speaking.
            </div>
            <DialogFooter className="flex !justify-center !flex-row items-center gap-6">
              <DialogClose asChild>
                <CommonButton variant={'default'} className="font-customFont w-36 md:w-40 h-12 font-bold" onClick={handleFixMic}>
                  Fix It
                </CommonButton>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </DialogOverlay>
      </CommonDialog>
    </div>
  );
};

export default CommonMicrophoneIssueModal;

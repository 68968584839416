import { useLocation, useNavigate } from 'react-router-dom';
import { cn } from '../../utils/conditionalClasses';
import React, { ReactElement } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

type Props = {
  index: number;
  toggleSubMenu: (url: string) => void;
  menuNavId: string;
  openedMenu: string | null;
  menuUrl: string;
  menuName: string;
  hasSubMenu?: boolean | null;
  parentNavigate?: boolean | null;
  menuIcon: ReactElement;
};

const Menu: React.FC<Props> = ({
  index,
  toggleSubMenu,
  menuNavId,
  openedMenu,
  menuUrl,
  menuName,
  hasSubMenu,
  parentNavigate,
  menuIcon,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <div
      className={cn(
        ' w-full flex rounded-[8px] justify-between items-center h-[42px] font-semibold text-sm cursor-pointer px-4 font-customFont',
        index !== 0 ? 'mt-3' : '',
        pathname.includes(menuUrl) ? 'bg-secondary-blue text-primary-blue' : ' text-third-gray'
      )}
      onClick={() => {
        if (parentNavigate) navigate(menuUrl);
        else toggleSubMenu(menuNavId);
      }}
    >
      <div className="flex gap-2 items-center">
        {menuIcon}
        {menuName}
      </div>
      {hasSubMenu ? openedMenu === menuNavId ? <ChevronUp width={'20px'} /> : <ChevronDown width={'20px'} /> : null}
    </div>
  );
};

export default Menu;

import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from './slices/AuthSlice';
import { constantsReducer } from './slices/ConstantSlice';
import { audioReducer } from './slices/AudioSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    constants: constantsReducer,
    audio: audioReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['audio/saveAudio'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['payload'],
        // Ignore these paths in the state
        ignoredPaths: ['audio.audio'],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

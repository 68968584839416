/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import WaveSurferContainer from '../../components/waveSurfer/WaveSurferContainer';
import CommonDiscardDialog from '../../components/CommonDiscardDialog';
import RecordRTC from 'recordrtc';
import { cn } from '../../utils/conditionalClasses';
import { CommonText } from '../../components/CommonText';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../context/hooks';
import { saveAudio } from '../../context/slices/AudioSlice';
import { useGetAccessToken } from '../../api/auth/actions/GetAccessToken';
import { convertFilesToMP3 } from '../../utils/audio/convertion';
import WaveAnimation from '../../components/waveSurfer/WaveAnimation';
import MicrophoneDialog from '../../components/MicrophoneDialog';
import CommonConfirmationModal from '../../components/CommonConfirmationModal';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const RecordAudio = () => {
  useGetAccessToken();
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [isDiscarded, setIsDiscarded] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<RecordRTC | null>(null);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [microphoneIsDissmised, setMicrophoneIsDissmised] = useState(false);
  const [microphoneIsDenied, setMicrophoneIsDenied] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    const languageFromAuth0 = searchParams.get('lang');
    if (languageFromAuth0) {
      i18next.changeLanguage(languageFromAuth0);
    }
  }, [searchParams]);

  const { t } = useTranslation('common');

  const requestMicAccess = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      setMicrophoneIsDissmised(false); // Access granted
      setMicrophoneIsDenied(false); // Access granted
    } catch (error: any) {
      console.log({ error });

      if (error?.message === 'Permission denied') {
        setMicrophoneIsDissmised(false);
        setMicrophoneIsDenied(true);
      } else {
        if (error?.message === 'Permission dismissed') {
          setMicrophoneIsDenied(false);
          setMicrophoneIsDissmised(true);
        }
      }
    }
  };

  useEffect(() => {
    requestMicAccess();
  }, []);

  const commonStyle = 'h-10 w-1/2 flex justify-center items-center cursor-pointer text-base font-bold';
  const selectedSectionStyle = 'bg-primary-blue text-white';
  const nonSelectedSectionStyle = 'bg-primary-gray text-primary-dark';

  const handleDiscard = () => {
    setIsDiscarded(true);
    setTimeout(() => {
      setIsDiscarded(false);
      setAudioBlob(null);
      setMediaRecorder(null);
    }, 2500);
  };

  const handleStopRecord = () => {
    mediaRecorder?.stopRecording(async () => {
      const webmBlob = mediaRecorder?.getBlob();
      if (webmBlob) {
        const mp3Blob = await convertFilesToMP3(webmBlob);
        setAudioBlob(mp3Blob);
      }
    });
    setIsRecording && setIsRecording(false);
  };

  const handleRecord = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new RecordRTC(stream, { type: 'audio' });
        recorder.startRecording();
        setMediaRecorder && setMediaRecorder(recorder);
        setIsRecording && setIsRecording(true);
      })
      .catch((err) => {
        console.error('Failed to start recording', err);
      });
  };

  const handelGenerate = () => {
    dispatch(saveAudio(audioBlob));
    navigate('/home/voiceRecord/reportDetails');
  };

  return (
    <>
      <MicrophoneDialog
        isOpen={microphoneIsDissmised}
        handleDiscard={() => setMicrophoneIsDissmised(false)}
        handleSave={async () => {
          setMicrophoneIsDissmised(false);
          await requestMicAccess();
        }}
      />
      <CommonConfirmationModal
        showIcon={false}
        showXIcon={true}
        closeModal={() => setMicrophoneIsDenied(false)}
        isConfirmed={microphoneIsDenied}
        primaryText={t('record.unableMicAccess')}
        secondaryText={t('record.disableMic')}
      />
      <CommonText
        className="text-primary-dark font-bold text-2xl mt-4 md:mt-0 flex justify-center md:justify-start "
        text={t('record.addNewRecording')}
      />
      <div className="w-full h-42 flex mt-10">
        <div className={cn('rounded-r-none rounded-l-md', commonStyle, selectedSectionStyle)} onClick={() => navigate('/home/voiceRecord')}>
          {t('record.voiceRecorder')}
        </div>
        <div
          className={cn('rounded-l-none rounded-r-md', commonStyle, nonSelectedSectionStyle)}
          onClick={() => navigate('/home/uploadRecord')}
        >
          {t('record.uploadRecording')}
        </div>
      </div>
      <div className="h-[82%] md:h-[600px] justify-center flex items-center flex-col md:justify-start">
        {audioBlob && <WaveSurferContainer audioBlob={audioBlob} />}
        {!audioBlob && (
          <WaveAnimation
            micAccessDenied={microphoneIsDissmised && microphoneIsDenied}
            handleStopRecord={handleStopRecord}
            handleRecord={handleRecord}
            isRecording={isRecording}
            setIsRecording={setIsRecording}
            requestMicAccess={requestMicAccess}
          />
        )}
        {audioBlob && (
          <div className="z-10 fixed bottom-0 flex items-center md:relative w-full md:mt-16 bg-white h-24">
            <CommonDiscardDialog
              saveButtonName={t('voiceActions.generateReport')}
              primaryDescardedMessage={t('modals.recordingDiscarded')}
              secondaryDescardedMessage={t('modals.startOver')}
              isDiscarded={isDiscarded}
              handleDiscard={handleDiscard}
              handleSave={handelGenerate}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default RecordAudio;

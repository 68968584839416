import { ChangeEvent, useRef, useState } from 'react';
import WaveSurferContainer from '../../components/waveSurfer/WaveSurferContainer';
import UploadingForm from '../../components/waveSurfer/components/upload/UploadingForm';
import { CommonText } from '../../components/CommonText';
import { cn } from '../../utils/conditionalClasses';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../context/hooks';
import { saveAudio } from '../../context/slices/AudioSlice';
import { useGetAccessToken } from '../../api/auth/actions/GetAccessToken';
import CommonDiscardDialog from '../../components/CommonDiscardDialog';
import { convertFilesToMP3, isMp3File } from '../../utils/audio/convertion';
import { useTranslation } from 'react-i18next';

const UploadAudio = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [isDiscarded, setIsDiscarded] = useState(false);
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useAppDispatch();
  const commonStyle = 'h-10 w-1/2 flex justify-center items-center cursor-pointer text-base font-bold';
  const selectedSectionStyle = 'bg-primary-blue text-white';
  const nonSelectedSectionStyle = 'bg-primary-gray text-primary-dark';

  const handleClick = () => {
    fileInputRef?.current?.click();
  };

  const { t } = useTranslation('common');

  useGetAccessToken();

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsUploading(true);
    const file = e.target.files?.[0];

    if (file) {
      let mp3FileToUpload: File | null = file;
      const isFileMP3 = isMp3File(file);
      if (!isFileMP3) {
        mp3FileToUpload = await convertFilesToMP3(file);
      }

      setTimeout(() => {
        setIsUploading(false);
        setIsUploaded(true);
      }, 1500);

      setTimeout(() => {
        setIsUploaded(false);
        setAudioBlob(mp3FileToUpload);
      }, 3000);

      if (fileInputRef.current) fileInputRef.current.value = '';
    }
  };

  const handleDiscard = () => {
    setIsDiscarded(true);
    setTimeout(() => {
      setIsDiscarded(false);
      setAudioBlob(null);
    }, 2500);
  };

  const handelGenerate = () => {
    dispatch(saveAudio(audioBlob));
    navigate('/home/uploadRecord/reportDetails');
  };

  return (
    <>
      <CommonText
        className="text-primary-dark font-bold text-2xl mt-4 md:mt-0 flex justify-center md:justify-start "
        text={t('record.uploadRecording')}
      />
      <div className="w-full h-42 flex mt-10">
        <div
          className={cn('rounded-r-none rounded-l-md', commonStyle, nonSelectedSectionStyle)}
          onClick={() => navigate('/home/voiceRecord')}
        >
          {t('record.voiceRecorder')}
        </div>
        <div
          className={cn('rounded-l-none rounded-r-md', commonStyle, selectedSectionStyle)}
          onClick={() => navigate('/home/uploadRecord')}
        >
          {t('record.uploadRecording')}
        </div>
      </div>
      {!audioBlob ? (
        <UploadingForm
          isUploaded={isUploaded}
          isUploading={isUploading}
          handleClick={handleClick}
          handleUpload={(e: ChangeEvent<HTMLInputElement>) => handleUpload(e)}
          fileInputRef={fileInputRef}
        />
      ) : (
        <div className="h-[82%] md:h-[600px] justify-center flex items-center flex-col md:justify-start">
          <WaveSurferContainer audioBlob={audioBlob} />
          <div className="fixed z-10 bottom-0 ml-[-16px] md:pr-0 flex items-center md:relative w-full md:mt-16 bg-white h-24">
            <CommonDiscardDialog
              saveButtonName={t('voiceActions.generateReport')}
              primaryDescardedMessage={t('modals.recordingDiscarded')}
              secondaryDescardedMessage={t('modals.startOver')}
              isDiscarded={isDiscarded}
              handleDiscard={handleDiscard}
              handleSave={handelGenerate}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default UploadAudio;

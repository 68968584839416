import React from 'react';
import SetAllImage from '../../assets/icons/SetAllIcon.svg';
import { CommonText } from '../CommonText';
import { CommonButton } from '../CommonButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SetAll = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  return (
    <div className="flex justify-center h-[98vh] flex-col items-center">
      <img src={SetAllImage} alt={'Set All'} width={'120px'} height={'120px'} />
      <CommonText className="text-primary-dark font-bold text-3xl mt-6" text={t('profileInfo.allSet')} />
      <CommonText className="text-secondary-dark text-lg mt-6" text={t('profileInfo.successfullyPurchased')} />
      <CommonButton className="mt-12" onClick={() => navigate('/home/voiceRecord')}>
        {t('profileInfo.startExploring')}
      </CommonButton>
    </div>
  );
};

export default SetAll;

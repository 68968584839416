import { useAppDispatch } from '../../../context/hooks';
import { saveAccessToken, saveIdToken } from '../../../context/slices/AuthSlice';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

const isTokenExpired = (token: string) => {
  if (!token) return true;

  const decodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000;

  return decodedToken?.exp !== undefined && decodedToken?.exp < currentTime;
};

export const useGetAccessToken = () => {
  const { getAccessTokenSilently, getIdTokenClaims, logout } = useAuth0();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        console.log('TOKEN', token);
        if (isTokenExpired(token)) {
          logout();
        } else dispatch(saveAccessToken(token));
      } catch (e) {
        console.error(e);
      }
    };

    const getIdToken = async () => {
      try {
        const token = await getIdTokenClaims();
        token && dispatch(saveIdToken(token?.__raw));
        console.log('ID TOKEN', token?.__raw);
      } catch (e) {
        console.error(e);
      }
    };

    getAccessToken();
    getIdToken();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getAccessTokenSilently, getIdTokenClaims]);
};

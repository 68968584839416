import React, { ReactElement } from 'react';

interface CommonTextProps {
  text?: string | ReactElement;
  className?: string;
  onClickAction?: () => void;
}

export const CommonText: React.FC<CommonTextProps> = ({ text, className, onClickAction }) => {
  return (
    <p className={`font-customFont ${className}`} onClick={onClickAction}>
      {text}
    </p>
  );
};

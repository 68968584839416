import { locale } from '../../i18n';
import { z } from 'zod';

export const getMarketingPreferencesSchema = () => {
  const MarketingPreferencesSchema = z.object({
    emailNotifications: z.string({
      message: locale.t('validation.selectOption'),
    }),
    smsNotifications: z.string({
      message: locale.t('validation.selectOption'),
    }),
  });
  return MarketingPreferencesSchema;
};

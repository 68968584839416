import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type Audio = Blob | null;

interface AudioState {
  audio: Blob | null;
}

const initialState: AudioState = {
  audio: null,
};

export const audioSlice = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    saveAudio: (state, action: PayloadAction<Audio>) => {
      state.audio = action.payload;
    },
  },
});

export const { saveAudio } = audioSlice.actions;
export const audioReducer = audioSlice.reducer;

import { ArrowLeft, Crown, StickyNote } from 'lucide-react';
import { CommonText } from '../../../components/CommonText';
import { useNavigate } from 'react-router-dom';
import { cn } from '../../../utils/conditionalClasses';
import { useMediaQuery } from 'react-responsive';
import { locale } from '../../../i18n';
import { useTranslation } from 'react-i18next';

const sections = () => [
  {
    title: locale.t('settings.manageSubscriptions'),
    secondTitle: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    logo: <Crown className="w-6 h-6" />,
    pathToNavigate: '',
  },
  {
    title: locale.t('settings.invoices'),
    secondTitle: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    logo: <StickyNote className="w-6 h-6" />,
    pathToNavigate: '',
  },
];

const BillingInformation = () => {
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { t } = useTranslation();
  const translatedSections = sections();
  
  return (
    <div className="p-4 md:p-0">
      <div className="flex mt-4 md:mt-0 items-center md:justify-start">
        {isTabletOrMobile && <ArrowLeft onClick={() => navigate('/settings')} />}
        <CommonText className="text-primary-dark font-bold text-2xl m-auto md:m-0" text={t('settings.billingInformation')} />
      </div>
      {translatedSections.map((section, index) => {
        return (
          <div
            className={cn('w-full h-42 flex gap-3 cursor-pointer', index === 0 ? 'mt-4 md:mt-10' : 'mt-4')}
            onClick={() => navigate(section?.pathToNavigate || '')}
          >
            <div className="w-[60px] h-[60px] rounded-full border-[1px] border-primary-gray flex justify-center items-center">
              {section.logo}
            </div>
            <div className="flex flex-col text-left w-[calc(100%-60px)]">
              <CommonText className="text-lg font-semibold font-customFont" text={section?.title} />
              <CommonText className="text-sm font-medium font-customFont" text={section?.secondTitle} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BillingInformation;

import { useNavigate } from 'react-router-dom';
import { cn } from '../../utils/conditionalClasses';
import { CommonText } from '../CommonText';
import { Ban, Check, ChevronRight, Info, OctagonAlert, ShieldCheck, Star } from 'lucide-react';
import { selectAccessToken } from '../../context/selectors/AuthSelectors';
import { useAppSelector } from '../../context/hooks';
import { useApi } from '../../api/useApi';
import { useGetAccessToken } from '../../api/auth/actions/GetAccessToken';
import { useTranslation } from 'react-i18next';

const NotificationIcon = ({ type }: { type: number }) => {
  const getIconByType = (type: number) => {
    switch (type) {
      case 0:
        return <Check />;
      case 1:
        return <OctagonAlert />;
      case 2:
        return <Ban />;
      case 3:
        return <ShieldCheck />;
      case 4:
        return <Info />;
      case 5:
        return <Star />;
      default:
        return <Info />;
    }
  };

  return <div className="notification-icon">{getIconByType(type)}</div>;
};

export const Notification = ({ notificationList, loading }: { notificationList: any; loading: boolean }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  useGetAccessToken();
  const accessToken = useAppSelector(selectAccessToken);
  const { put } = useApi();

  const handleNavigate = async (notification: any) => {
    if (notification?.notificationOwner === 0 && accessToken) {
      await put('/notifications/update', {
        id: notification.id,
        isRead: true,
      });
      navigate(`/library/report/${notification.data}`);
    }
  };

  return (
    <>
      {notificationList?.map((notification: any, index: number) => (
        <div
          onClick={() => handleNavigate(notification)}
          className={cn('flex justify-between gap-4 cursor-pointer', index > 0 ? 'mt-6' : '')}
        >
          <div className="w-12 h-12 rounded-full border-[1px] border-primary-gray flex justify-center items-center">
            <NotificationIcon type={notification.type} />
          </div>
          <div className="flex flex-col w-[236px]">
            <div className="flex w-fit">
              <CommonText className="w-[215px] font-semibold text-lg text-primary-dark font-customFont" text={notification?.title} />{' '}
              {!notification.isRead && <div className="w-3 h-3 rounded-full bg-primary-blue mt-2"></div>}
            </div>

            <CommonText className="font-medium text-sm font-customFont text-third-gray" text={notification?.description} />
          </div>

          <ChevronRight />
        </div>
      ))}
      {loading && <CommonText text={t('loading')} />}
    </>
  );
};

import { z } from 'zod';
import { locale } from '../../i18n';

const eighteenYearsAgo = new Date();
eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

export const getMyProfileSchema = () => {
  const MyProfileSchema = z.object({
    title: z.string({
      message: locale.t('validation.titleRequired'),
    }),
    firstName: z
      .string({
        message: locale.t('validation.firstNameRequired'),
      })
      .min(2, {
        message: locale.t('validation.firstNameMinLength'),
      }),
    lastName: z
      .string({
        message: locale.t('validation.lastNameRequired'),
      })
      .min(2, {
        message: locale.t('validation.lastNameMinLength'),
      }),
    email: z.string({
      message: locale.t('validation.emailRequired'),
    }),
    phoneNumber: z
      .string({
        message: locale.t('validation.phoneNumberRequired'),
      })
      .min(8, {
        message: locale.t('validation.phoneNumberDigits'),
      })
      .max(8, {
        message: locale.t('validation.phoneNumberExactDigits'),
      }),
    gender: z.string({
      message: locale.t('validation.genderRequired'),
    }),
    birthdate: z
      .date({
        message: locale.t('validation.birthdateRequired'),
      })
      .refine((date) => date <= eighteenYearsAgo, {
        message: locale.t('validation.minimumAgeRequirement'),
      }),
    clinics: z
      .array(
        z.object({
          name: z
            .string({
              message: locale.t('validation.companyNameRequired'),
            })
            .optional(),
          vatReg: z
            .string({
              message: locale.t('validation.vatRegRequired'),
            })
            .optional(),
          registrationNumber: z
            .string({
              message: locale.t('validation.registrationNumberRequired'),
            })
            .optional(),
          address: z
            .string({
              message: locale.t('validation.addressRequired'),
            })
            .optional(),
        })
      )
      .nullable()
      .optional(),
  });

  return MyProfileSchema;
};
import { Editor } from '@tiptap/react';
import './style.css';
import { Bold, Italic, Redo, Strikethrough, Undo, WrapText } from 'lucide-react';

const MenuBar = ({ editor }: { editor: Editor | null }) => {
  if (!editor) {
    return null;
  }

  const commonStyle = 'rounded w-[30px] h-[30px] flex items-center justify-center cursor-pointer';
  const activeStyle = commonStyle + ' bg-primary-blue text-white cursor-pointer';
  const disabledStyle = 'rounded w-[30px] h-[30px] flex items-center justify-center text-secondary-gray cursor-not-allowed';

  const disabledRedo = !editor.can().chain().focus().redo().run();
  const disabledUndo = !editor.can().chain().focus().undo().run();

  return (
    <div className="flex justify-end gap-4 my-4">
      <div className={editor.isActive('bold') ? activeStyle : commonStyle}>
        <Bold width={'18px'} height={'18px'} onClick={() => editor.chain().focus().toggleBold().run()} />
      </div>

      <div className={editor.isActive('italic') ? activeStyle : commonStyle}>
        <Italic width={'18px'} height={'18px'} onClick={() => editor.chain().focus().toggleItalic().run()} />
      </div>

      <div className={editor.isActive('strike') ? activeStyle : commonStyle}>
        <Strikethrough width={'18px'} height={'18px'} onClick={() => editor.chain().focus().toggleStrike().run()} />
      </div>

      <div className={commonStyle}>
        <WrapText width={'18px'} height={'18px'} onClick={() => editor.chain().focus().setHardBreak().run()} />
      </div>
      <div className={disabledUndo ? disabledStyle : commonStyle}>
        <Undo width={'18px'} height={'18px'} onClick={() => editor.chain().focus().undo().run()} />
      </div>
      <div className={disabledRedo ? disabledStyle : commonStyle}>
        <Redo
          width={'18px'}
          height={'18px'}
          onClick={() => {
            editor.chain().focus().redo().run();
          }}
        />
      </div>
    </div>
  );
};

export default MenuBar;

import React from 'react';
import { CommonDialog, DialogContent, DialogHeader, DialogOverlay, DialogTitle } from './CommonDialog';
import { LoadingSpinner } from './CommonLoadingSpinner';

interface Props {
  isLoading: boolean;
  primaryText: string;
  secondaryText: string;
}

const CommonLoadingModal: React.FC<Props> = ({ isLoading, primaryText, secondaryText }) => {
  return (
    <CommonDialog open={isLoading}>
      <DialogOverlay>
        <DialogContent className="sm:max-w-[425px] rounded-tl-2xl rounded-tr-2xl border-none bg-white top-[87%] md:top-1/2 md:mt-0">
          <DialogHeader>
            <DialogTitle className="font-customFont flex justify-center font-bold text-2x items-center">
              <LoadingSpinner className="w-14 h-14 text-primary-blue" />
            </DialogTitle>
          </DialogHeader>
          <div className="font-customFont flex justify-center font-bold text-2xl text-center">{primaryText}</div>
          <div className="font-customFont text-third-gray flex justify-center font-normal text-base text-center">{secondaryText}</div>
        </DialogContent>
      </DialogOverlay>
    </CommonDialog>
  );
};

export default CommonLoadingModal;

import { cn } from '../../utils/conditionalClasses';
import { EllipsisVertical, FileText } from 'lucide-react';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { CommonText } from '../CommonText';
import { formatDateTime } from '../../utils/formatters';
import { ILibrary } from '../../interfaces/library';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LibraryRow = ({
  row,
  selectedRows,
  allowSelecting,
  setSelectedRows,
}: {
  row: ILibrary;
  selectedRows: string[] | null;
  allowSelecting: boolean;
  setSelectedRows: Dispatch<SetStateAction<string[] | null>>;
}) => {
  const [isRowHovered, setIsRowHovered] = useState(false);
  const { date, time } = formatDateTime(row.createdByTimestamp as string);
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  return (
    <div
      onMouseEnter={() => {
        if (!allowSelecting) {
          if (!row?.sessionContext) {
            setIsRowHovered(true);
          } else setIsRowHovered(false);
        }
      }}
      onClick={() => {
        if (allowSelecting) {
          if (selectedRows?.includes(row.id)) {
            const newRowsSelected = selectedRows.filter((selectedRow) => selectedRow !== row.id);
            setSelectedRows([...newRowsSelected]);
          } else {
            setSelectedRows((prevState) => [...(prevState || []), row.id]);
          }
        } else {
          if (row?.sessionContext && row?.id) {
            navigate(`/library/report/${row?.id}`);
          }
        }
      }}
      onMouseLeave={() => setIsRowHovered(false)}
      className={cn(
        'relative mt-4 border-t-[1px] border-b-[1px] border-primary-gray h-[70px] flex justify-between items-center',
        row?.sessionContext ? 'cursor-pointer' : '',
        selectedRows?.includes(row.id) ? 'bg-[#EDF2FF]' : ''
      )}
    >
      <div className="flex">
        <div
          className={cn(
            'border-[1px] border-primary-gray flex items-center justify-center rounded-full w-14 h-14',
            selectedRows?.includes(row.id) ? 'border-[#6697FE]' : ''
          )}
        >
          <FileText className={cn(!row?.sessionContext ? 'text-primary-gray' : 'text-primary-dark')} />
        </div>
        <div className="w-[calc(100% - 56px)] flex flex-col justify-between h-14 ml-4">
          <CommonText className="font-semibold font-customFont text-xl" text={row.patientIdentifier} />
          <CommonText className="font-customFont font-normal text-sm text-third-gray" text={`${date} • ${time}`} />
        </div>
      </div>
      <div className="flex items-center justify-center w-14 h-14 cursor-pointer">
        <EllipsisVertical />
      </div>
      {isRowHovered ? (
        <div className="px-2 flex justify-center text-xs items-center rounded absolute h-8 text-white bg-primary-blue right-0">
          {t('library.sessionProcessed')}
        </div>
      ) : null}
    </div>
  );
};

export default LibraryRow;

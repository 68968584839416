/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAppSelector } from '../../context/hooks';
import { selectAccessToken } from '../../context/selectors/AuthSelectors';
import { Bell, Search } from 'lucide-react';
import Connector from './signalr-connection';
import { useEffect, useRef, useState } from 'react';
import { useGetAccessToken } from '../../api/auth/actions/GetAccessToken';
import { CommonPopover, PopoverContent, PopoverTrigger } from '../CommonPopover';
import { useApi } from '../../api/useApi';
import { Notification } from '../notifications/Notification';
import { cn } from '../../utils/conditionalClasses';
import { useTranslation } from 'react-i18next';
import ENFlag from '../../assets/icons/unitedKingdom.png';
import GRFlag from '../../assets/icons/greece.png';
import { locale } from '../../i18n';
import i18next from 'i18next';
import { useSearchParams } from 'react-router-dom';

const Header: React.FC = () => {
  useGetAccessToken();

  const { i18n } = useTranslation('common');
  const currentLanguage = i18n.language;

  const accessToken = useAppSelector(selectAccessToken);
  const { get, put } = useApi();
  const { events } = Connector(accessToken);
  const [notificationsCount, setNotificationsCount] = useState<any>(null);
  const [newNotification, setNewNotification] = useState(null);
  const [notificationList, setNotificationList] = useState<any>(null);

  const [showNotificationList, setShowNotificationList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const notificationContainerRef = useRef<any>(null);

  const handleViewNotification = async (data: any) => {
    const idArray = data.filter((notification: any) => !notification?.isViewed).map((notification: any) => notification.id);
    if (idArray?.length > 0) {
      await put('/notifications/viewed', {
        ids: [...idArray],
      });
    }
  };

  const getDataFromSignalR = () => {
    events(
      (notificationsCount) => {
        setNotificationsCount(notificationsCount);
      },
      (newNotification) => {
        setNewNotification(newNotification);
      }
    );
  };

  useEffect(() => {
    getDataFromSignalR();
  });

  const getMoreNotifications = async (skip: number) => {
    if (loading || !hasMore) return;
    setLoading(true);
    try {
      const response: any = await get(`/notifications/list?take=${20}&skip=${skip}`);
      if (response?.data?.data) {
        const data = response?.data?.data;
        if (data?.length > 0) {
          setNotificationList((prevState: any) => [...(prevState || []), ...data]);
          await handleViewNotification(data);
        } else setHasMore(false);
      }
      getDataFromSignalR();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getNotifications = async () => {
    try {
      const response: any = await get(`/notifications/list?take=${20}&skip=${0}`);
      if (response?.data?.data) {
        const data = response?.data?.data;
        if (data?.length > 0) {
          setNotificationList(data);
          await handleViewNotification(data);
        }
      }
      getDataFromSignalR();
    } catch (e) {
      console.error(e);
    }
  };

  const handleNotify = async (value: boolean) => {
    if (value) {
      await getNotifications();
    }
    setShowNotificationList(value);
    getDataFromSignalR();
  };

  const handleScroll = () => {
    if (notificationContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = notificationContainerRef.current;
      if (scrollTop + clientHeight >= scrollHeight && !loading) {
        getMoreNotifications(notificationList?.length);
      }
    }
    getDataFromSignalR();
  };

  useEffect(() => {
    if (showNotificationList) {
      setTimeout(() => {
        const container = notificationContainerRef.current;
        if (container) {
          container.addEventListener('scroll', handleScroll);
        }
      }, 0);
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const container = notificationContainerRef.current;
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotificationList, loading]);

  const changeLanguage = (lang: 'en' | 'gr') => {
    i18next.changeLanguage(lang);
    i18next.reloadResources();
  };

  return (
    <header className="bg-white p-4 flex justify-end items-center min-h-[88px] border-b-[1px] border-b-primary-gray border-l-white ">
      <div className="flex gap-2 justify-end mr-4">
        <div
          onClick={() => changeLanguage('gr')}
          className={cn(
            'flex justify-center items-center',
            currentLanguage === 'gr' ? 'bg-primary-blue rounded-md bg-opacity-10 px-2 cursor-pointer' : 'cursor-pointer'
          )}
        >
          <img className="w-9" src={GRFlag} alt="gr flag" />
        </div>
        <div
          onClick={() => changeLanguage('en')}
          className={
            (cn('flex justify-center items-center'),
            currentLanguage === 'en' ? 'bg-primary-blue rounded-md bg-opacity-10 px-2 cursor-pointer' : 'cursor-pointer')
          }
        >
          <img src={ENFlag} className="w-9" alt="en flag" />
        </div>
      </div>
      <div className="flex items-center space-x-4">
        <CommonPopover open={showNotificationList} onOpenChange={(value) => handleNotify(value)}>
          <PopoverTrigger asChild>
            <button className="flex items-center justify-center w-14 h-14 rounded-full border border-primary-gray text-gray-700 focus:outline-none">
              <Bell />
              {notificationsCount && notificationsCount > 0 && (
                <div className="absolute w-2 h-2 rounded-full bg-primary-error top-9 right-8"></div>
              )}
            </button>
          </PopoverTrigger>
          <PopoverContent ref={notificationContainerRef} className="border-none h-full overflow-auto bg-white md:max-h-[700px] ml-[-10px]">
            <Notification notificationList={notificationList} loading={loading} />
          </PopoverContent>
        </CommonPopover>
      </div>
    </header>
  );
};

export default Header;

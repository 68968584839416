import React from 'react';
import { CommonDialog, DialogContent, DialogHeader, DialogOverlay, DialogTitle } from './CommonDialog';
import { Check, X } from 'lucide-react';
import { cn } from '../utils/conditionalClasses';

interface Props {
  closeModal?: () => void;
  isConfirmed: boolean;
  primaryText: string;
  secondaryText: string;
  showIcon?: boolean;
  showXIcon?: boolean;
}

const CommonConfirmationModal: React.FC<Props> = ({
  closeModal,
  isConfirmed,
  primaryText,
  secondaryText,
  showIcon = true,
  showXIcon = false,
}) => {
  return (
    <CommonDialog open={isConfirmed}>
      <DialogOverlay onClick={() => closeModal && closeModal()}>
        <DialogContent className="sm:max-w-[425px] rounded-tl-2xl rounded-tr-2xl !border-none bg-white top-[90%] md:top-1/2 md:mt-0">
          <DialogHeader>
            <DialogTitle
              className={cn('font-customFont flex justify-center font-bold text-2x items-center', showXIcon ? 'justify-end' : '')}
            >
              {showIcon && (
                <div className="w-14 h-14 rounded-full text-white bg-primary-blue flex justify-center items-center">
                  <Check />
                </div>
              )}
              {showXIcon && <X onClick={closeModal} className="text-secondary-gray w-6 h-6 cursor-pointer" />}
            </DialogTitle>
          </DialogHeader>
          <div className="font-customFont flex justify-center font-bold text-2xl text-center">{primaryText}</div>
          <div className="font-customFont text-third-gray flex justify-center font-normal text-base text-center">{secondaryText}</div>
        </DialogContent>
      </DialogOverlay>
    </CommonDialog>
  );
};

export default CommonConfirmationModal;

import { Library, Mic, Settings } from 'lucide-react';
import { CommonText } from '../CommonText';
import { cn } from '../../utils/conditionalClasses';
import { useLocation, useNavigate } from 'react-router-dom';
import { locale } from '../../i18n';
import { useTranslation } from 'react-i18next';

const menus = () => [
  { text: locale.t('nav.newRecording'), icon: <Mic className="w-6 h-6" />, pathname: ['/home/uploadRecord', '/home/voiceRecord'] },
  { text: locale.t('nav.myLibrary'), icon: <Library className="w-6 h-6" />, pathname: ['/library'] },
  { text: locale.t('nav.settings'), icon: <Settings className="w-6 h-6" />, pathname: ['/settings'] },
];

const MobileMenu = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();
  const menuList = menus();

  return (
    <>
      <div className="fixed bottom-0 h-24 w-full bg-border-gray flex justify-around ">
        {menuList.map((menu) => {
          const menuIsActive = menu.pathname.some((path) => pathname.includes(path));
          const handleRedirect = () => navigate(menu.pathname[0]);
          return (
            <div
              onClick={handleRedirect}
              className={cn(
                'flex flex-col justify-center items-center text-third-gray',
                menuIsActive ? 'text-primary-blue' : 'text-third-gray'
              )}
            >
              {menu.icon}
              <CommonText className="font-bold text-[10px] mt-2" text={menu.text} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MobileMenu;

import { locale } from '../../i18n';
import { z } from 'zod';

export const getReportDetailsSchema = () => {
  const ReportDetailsSchema = z.object({
    title: z.string({
      message: locale.t('validation.titleRequired'),
    }),
    patientidentifier: z.string({
      message: locale.t('validation.titleRequired'),
    }),
    patientname: z.string({
      message: locale.t('validation.patientNameRequired'),
    }),
    language: z.number({
      message: locale.t('validation.languageRequired'),
    }),
    sessionLength: z.number({
      message: locale.t('validation.reportLengthRequired'),
    }),
    sessionLayouts: z.array(z.number()).nullable(),
  });
  return ReportDetailsSchema;
};

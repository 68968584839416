import { useLocation, useNavigate } from 'react-router-dom';
import { cn } from '../../utils/conditionalClasses';
import React, { Dispatch, memo, SetStateAction } from 'react';

type Props = {
  index: number;
  menuNavId: string;
  menuUrl: string;
  nameSubMenu: string;
  redirectToForSubMenu: string;
  setOpenedMenu: Dispatch<SetStateAction<string | null>>;
};

const SubMenu: React.FC<Props> = ({ index, redirectToForSubMenu, nameSubMenu, menuNavId, menuUrl, setOpenedMenu }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <div
      key={index}
      className={cn(
        'flex mt-3 justify-start items-center rounded-[8px] w-full h-[35px] font-medium text-xs select-none pl-4 font-customFont',
        pathname.includes(redirectToForSubMenu) ? 'bg-secondary-blue text-primary-blue' : ''
      )}
      onClick={() => {
        setOpenedMenu(menuNavId);
        navigate(`${menuUrl}${redirectToForSubMenu}`);
      }}
    >
      {nameSubMenu}
    </div>
  );
};

export default memo(SubMenu);

import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosInterceptorManager, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useAppSelector } from '../context/hooks';
import { selectAccessToken, selectIdToken } from '../context/selectors/AuthSelectors';

const BASE_URL = 'https://healthtech.azurewebsites.net/api/';

export const CONFIG: AxiosRequestConfig = {
  headers: {
    Authorization: '',
    IdToken: '',
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
    'Accept-Language': 'en',
  },
};

const useAddAuthRequestInterceptor = (manager: AxiosInterceptorManager<AxiosRequestConfig>) => {
  const accessToken = useAppSelector(selectAccessToken);
  const idToken = useAppSelector(selectIdToken);

  manager.use(
    (config) => {
      const headers = config.headers;

      if (headers) {
        if (accessToken !== null) {
          headers.Authorization = `Bearer ${accessToken}`;
        }
        if (idToken !== null) {
          headers.IdToken = idToken;
        }
      }

      return config;
    },
    (error) => {
      throw Error(error);
    }
  );
};

const useAddAuthResponseInterceptor = (manager: AxiosInterceptorManager<AxiosResponse>) => {
  const { logout } = useAuth0();

  manager.use(
    (response) => response,
    async (error) => {
      if (error.response) {
        if (error.response.status === 401) {
          logout();
        }
      }

      return Promise.reject(error);
    }
  );
};

const useAddServerErrorInterceptor = (manager: AxiosInterceptorManager<AxiosResponse>) => {
  manager.use(
    (response) => response,
    async (error) => {
      if (error.response) {
        // toastShowAction({
        //   text: error.response.data.detail || "Something went wrong!",
        //   type: "error",
        // });
      }

      return Promise.reject(error);
    }
  );
};

const useAddPathInterceptor = (manager: AxiosInterceptorManager<AxiosRequestConfig>) => {
  manager.use(
    async (config) => {
      if (config.method === 'PATCH' || config.method === 'patch') {
        const headers = config.headers;

        if (headers) {
          headers['Content-Type'] = 'application/merge-patch+json';
        }
      }

      return config;
    },
    (error) => Promise.reject(error)
  );
};

export const instance = axios.create({
  baseURL: BASE_URL,
  headers: CONFIG.headers,
});

export const useCreateAxiosAuth = () => {
  useAddAuthRequestInterceptor(instance.interceptors.request);
  useAddAuthResponseInterceptor(instance.interceptors.response);

  useAddPathInterceptor(instance.interceptors.request);
  useAddServerErrorInterceptor(instance.interceptors.response);

  return instance;
};

export const useCreateAxiosWithoutAuth = () => {
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: CONFIG.headers,
  });
  useAddPathInterceptor(instance.interceptors.request);

  return instance;
};

import React, { ChangeEvent } from 'react';
import UploadIcon from '../../../../assets/icons/upload.svg';
import UploadedIcon from '../../../../assets/icons/uploaded.svg';
import { LoadingSpinner } from '../../../CommonLoadingSpinner';
import { useTranslation } from 'react-i18next';

interface Props {
  isUploading: boolean;
  handleClick: () => void;
  isUploaded: boolean;
  fileInputRef: any;
  handleUpload: (e: ChangeEvent<HTMLInputElement>) => void;
}

const UploadingForm: React.FC<Props> = ({ handleClick, isUploaded, fileInputRef, isUploading, handleUpload }) => {
  const { t } = useTranslation('common');
  return (
    <div
      className="flex flex-col items-center justify-center w-full h-[500px] border border-gray-300 bg-primary-gray border-input-gray rounded-lg cursor-pointer bg-gray-50 mt-12"
      onClick={handleClick}
    >
      {isUploading ? (
        <>
          <LoadingSpinner className="w-14 h-14 text-primary-blue" />
          <p className="font-customFont mt-4 text-lg text-secondary-dark font-medium">{t('voiceActions.uploading')}</p>
        </>
      ) : isUploaded ? (
        <>
          <div className="font-customFont text-secondary-dark text-xl font-bold">
            <img src={UploadedIcon} alt="upload" />
          </div>
          <p className=" font-customFont mt-4 text-lg text-secondary-dark font-medium">{t('newRecording.uploadSuccessfully')}</p>
        </>
      ) : (
        <>
          <div className="font-customFont text-secondary-dark text-xl font-bold">
            <img src={UploadIcon} alt="upload" />
          </div>
          <p className="font-customFont mt-4 text-lg text-secondary-dark font-medium">{t('upload.supportFile')}</p>
          <p className=" font-customFonttext-base mt-4  text-secondary-dark font-medium">{t('upload.maxFileSize')}</p>
        </>
      )}
      <input type="file" accept="audio/*" ref={fileInputRef} className="hidden" onChange={handleUpload} />
    </div>
  );
};

export default UploadingForm;

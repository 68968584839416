import { useFormContext, useWatch } from 'react-hook-form';
import { CommonText } from '../../CommonText';
import { CommonButton } from '../../CommonButton';
import { CheckIcon } from 'lucide-react';
import { cn } from '../../../utils/conditionalClasses';
import DoctorClinics from './DoctorClinics';
import { locale } from '../../../i18n';
import { useTranslation } from 'react-i18next';

interface DoctorSpecialtyProps {
  isFormTooTall?: boolean;
}

const teamSizeTypes = () => [
  { value: 'individual', label: locale.t('profileInfo.individualMedical') },
  { value: 'company', label: locale.t('profileInfo.egCompany') },
];

export const BillingInformation: React.FC<DoctorSpecialtyProps> = ({ isFormTooTall }) => {
  const { control, setValue } = useFormContext();
  const { t } = useTranslation('common');
  const [teamSize] = useWatch({ name: ['teamSize'], control });

  const teamSizeOptions = teamSizeTypes();

  return (
    <>
      <div className="flex flex-col gap-2">
        <CommonText className="text-primary-dark font-bold text-3xl" text={t('profileInfo.billing')} />
        <CommonText className="text-secondary-dark text-lg" text={t('profileInfo.teamSize')} />
      </div>
      <div className="flex flex-col gap-2 mb-12">
        <div className="flex w-full flex-col">
          {teamSizeOptions.map((teamSizeType, index) => (
            <div
              onClick={() => {
                setValue('teamSize', teamSizeType.value);
                if (teamSizeType.value === 'company') setValue('clinics', [{ name: '', vatReg: '', address: '', registrationNumber: '' }]);
                else setValue('clinics', null);
              }}
              style={index === 1 ? { marginTop: '16px' } : {}}
              className={cn(
                'flex cursor-pointer w-full p-2 rounded-md h-auto items-center justify-between bg-inherit hover:bg-inherit, border-primary-gray bg-white border-[1px] min-h-16',
                teamSize === teamSizeType.value ? 'border-primary-blue' : ''
              )}
            >
              {teamSizeType.label}
              {teamSize === teamSizeType.value && <CheckIcon color={'#246BFD'} />}
            </div>
          ))}
        </div>
        {teamSize === 'company' && <DoctorClinics />}
        {teamSize && (
          <div className={`${isFormTooTall ? 'flex flex-col fixed bottom-0 bg-white pb-8 gap-4' : 'block mt-8'}`}>
            {isFormTooTall && <div className="bg-border-gray h-0.5" />}
            <CommonButton type={'submit'}>{t('continue')}</CommonButton>
          </div>
        )}
      </div>
    </>
  );
};

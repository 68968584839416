import {
  CommonDialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
  DialogTrigger,
} from '../components/CommonDialog';
import React from 'react';
import { CommonButton } from './CommonButton';
import CommonConfirmationModal from './CommonConfirmationModal';
import { useTranslation } from 'react-i18next';

interface Props {
  isDiscarded: boolean;
  handleDiscard: () => void;
  handleSave?: () => void;
  primaryDescardedMessage: string;
  secondaryDescardedMessage: string;
  saveButtonName: string;
  loading?: boolean;
}

const CommonDiscardDialog: React.FC<Props> = ({
  handleDiscard,
  isDiscarded,
  handleSave,
  primaryDescardedMessage,
  secondaryDescardedMessage,
  saveButtonName,
  loading,
}) => {
  const { t } = useTranslation('common');
  return (
    <div className="flex justify-center gap-4 items-center w-full">
      <CommonDialog>
        <DialogTrigger asChild>
          <CommonButton disabled={!!loading} variant={'secondary'} className="w-[45%] md:w-52">
            {t('discard')}
          </CommonButton>
        </DialogTrigger>
        <DialogOverlay>
          <DialogContent className="sm:max-w-[425px] rounded-tl-2xl rounded-tr-2xl border-none bg-white top-full -mt-[125px] md:top-1/2 md:mt-0">
            <DialogHeader>
              <DialogTitle className="font-customFont flex justify-center font-bold text-2xl text-primary-error">
                {t('modals.confirmDiscardChanges')}
              </DialogTitle>
            </DialogHeader>
            <div className="font-customFont py-4 flex justify-center font-medium text-xl text-center text-primary-dark">
              {t('modals.discardConfirmation')}
            </div>
            <DialogFooter className="flex !justify-center !flex-row items-center gap-6">
              <DialogClose asChild>
                <CommonButton className="font-customFont w-36 md:w-40 h-12 font-bold"> {t('modals.continueEditing')}</CommonButton>
              </DialogClose>
              <DialogClose asChild>
                <CommonButton className="font-customFont w-36 md:w-40 h-12 font-bold bg-primary-error" onClick={handleDiscard}>
                  {t('modals.yesDiscard')}
                </CommonButton>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </DialogOverlay>
      </CommonDialog>
      <CommonConfirmationModal isConfirmed={isDiscarded} primaryText={primaryDescardedMessage} secondaryText={secondaryDescardedMessage} />
      <CommonButton disabled={!!loading} onClick={handleSave} className="font-customFont w-[45%] md:w-52">
        {saveButtonName}
      </CommonButton>
    </div>
  );
};

export default CommonDiscardDialog;

import { z } from 'zod';
import { locale } from '../../i18n';

const eighteenYearsAgo = new Date();
eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

export const getOnboardingProfileSchema = () => {
  const OnboardingProfileSchema = z
    .object({
      title: z.string({
        message: locale.t('validation.titleRequired'),
      }),
      firstName: z
        .string({
          message: locale.t('validation.firstNameRequired'),
        })
        .min(2, {
          message: locale.t('validation.firstNameMinLength'),
        }),
      specialtyIds: z.array(z.string().nullable()).nullable(),
      lastName: z
        .string({
          message: locale.t('validation.lastNameRequired'),
        })
        .min(2, {
          message: locale.t('validation.lastNameMinLength'),
        }),
      gender: z.string({
        message: locale.t('validation.genderRequired'),
      }),
      phoneNumber: z
        .string({
          message: locale.t('validation.phoneNumberRequired'),
        })
        .min(8, {
          message: locale.t('validation.phoneNumberDigits'),
        })
        .max(8, {
          message: locale.t('validation.phoneNumberExactDigits'),
        }),
      birthdate: z
        .date({
          message: locale.t('validation.birthdateRequired'),
        })
        .refine((date) => date <= eighteenYearsAgo, {
          message: locale.t('validation.minimumAgeRequirement'),
        }),
      emailNotifications: z.string({
        message: locale.t('validation.selectOption'),
      }),
      smsNotifications: z.string({
        message: locale.t('validation.selectOption'),
      }),
      teamSize: z.string().nullable(),
      clinics: z
        .array(
          z.object({
            name: z
              .string({
                message: locale.t('validation.companyNameRequired'),
              })
              .optional(),
            vatReg: z
              .string({
                message: locale.t('validation.vatRegRequired'),
              })
              .optional(),
            registrationNumber: z
              .string({
                message: locale.t('validation.registrationNumberRequired'),
              })
              .optional(),
            address: z
              .string({
                message: locale.t('validation.addressRequired'),
              })
              .optional(),
          })
        )
        .nullable()
        .optional(),
    })
    .superRefine((data, ctx) => {
      if (data?.clinics && data.teamSize === 'company') {
        data.clinics.forEach((clinic, index) => {
          if (!clinic.name) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['clinics', index, 'name'],
              message: locale.t('validation.companyNameRequired'),
            });
          }
          if (!clinic.vatReg) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['clinics', index, 'vatReg'],
              message: locale.t('validation.vatRegRequired'),
            });
          }
          if (!clinic.registrationNumber) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['clinics', index, 'registrationNumber'],
              message: locale.t('validation.registrationNumberRequired'),
            });
          }
          if (!clinic.address) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['clinics', index, 'address'],
              message: locale.t('validation.addressRequired'),
            });
          }
        });
      }
    });
  return OnboardingProfileSchema;
};
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { useLocation } from 'react-router-dom';
import StopIcon from '../../assets/icons/stop.svg';
import PlayIcon from '../../assets/icons/play.svg';
import { formatTime } from './helper';
import { useMediaQuery } from 'react-responsive';

interface Props {
  audioBlob: Blob | null;
}

const WaveSurferContainer: React.FC<Props> = ({ audioBlob }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const { pathname } = useLocation();
  const waveformRef = useRef<HTMLDivElement | null>(null);
  const wavesurferRef = useRef<WaveSurfer | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    if (!wavesurferRef?.current) {
      wavesurferRef.current = WaveSurfer.create({
        container: waveformRef.current as HTMLDivElement,
        waveColor: '#E0E0E0', // Semi-transparent red
        progressColor: '#246BFD',
        height: isTabletOrMobile ? 300 : 400,
        barWidth: 8,
        barRadius: 10, // Radius for rounded corners of waveform bars
        cursorWidth: 0,
        autoplay: false,
        cursorColor: 'navy',
      });
    }

    if (!(pathname.includes('uploadRecord') || pathname.includes('voiceRecord'))) {
      return () => wavesurferRef.current?.destroy();
    }
  }, [pathname, isTabletOrMobile]);

  useEffect(() => {
    if (audioBlob && wavesurferRef.current) {
      const objectURL = URL.createObjectURL(audioBlob);
      wavesurferRef.current.load(objectURL);

      wavesurferRef.current.on('ready', () => {
        if (wavesurferRef && wavesurferRef.current) {
          setDuration(wavesurferRef.current.getDuration());
        }
      });

      wavesurferRef.current.on('audioprocess', () => {
        if (wavesurferRef && wavesurferRef.current) {
          setCurrentTime(wavesurferRef.current.getCurrentTime());
        }
      });

      wavesurferRef.current.on('finish', () => {
        setIsPlaying(false);
      });

      return () => URL.revokeObjectURL(objectURL);
    }
  }, [audioBlob]);

  const handlePlayPause = () => {
    if (wavesurferRef.current) {
      if (isPlaying) {
        wavesurferRef.current.pause();
      } else {
        wavesurferRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="w-full">
      <div
        ref={waveformRef}
        style={{
          display: `${!!audioBlob ? 'block' : 'hidden'}`,
          width: !!audioBlob ? '100%' : '0',
          position: 'relative',
        }}
      ></div>
      <div className="w-full md:mt-12">
        <div className="flex justify-between items-center">
          {!!audioBlob && <div className="w-fit">{formatTime(currentTime)}</div>}
          {!!audioBlob && (
            <div className="flex flex-col gap-2 justify-center items-center ">
              <button onClick={handlePlayPause}>{isPlaying ? <img src={StopIcon} alt="stop" /> : <img src={PlayIcon} alt="play" />}</button>
              <div className="font-customFont font-normal text-third-gray text-base">{(audioBlob as any)?.name}</div>
            </div>
          )}
          {!!audioBlob && <div className="w-fit">{formatTime(duration)}</div>}
        </div>
      </div>
    </div>
  );
};

export default WaveSurferContainer;

import { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm';
import { CommonText } from '../CommonText';
import RecordIcon from '../../assets/icons/record.svg';
import { useMediaQuery } from 'react-responsive';
import CommonMicrophoneIssueModal from '../CommonMicrophoneIssueModal';
import { useTranslation } from 'react-i18next';

const MIN_DECIBELS = -45;
const SILENCE_DURATION = 5000; // 5 seconds in milliseconds

const WaveAnimation = ({
  setIsRecording,
  isRecording,
  handleStopRecord,
  handleRecord,
  requestMicAccess,
}: {
  setIsRecording: Dispatch<SetStateAction<boolean>>;
  isRecording: boolean;
  micAccessDenied: boolean;
  handleStopRecord: () => void;
  handleRecord: () => void;
  requestMicAccess: () => void;
}) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const waveSurferRef = useRef<WaveSurfer | null>(null);
  const recordRef = useRef<any>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const silenceTimerRef = useRef<NodeJS.Timeout | null>(null);
  const animationFrameRef = useRef<number | null>(null); // Ref to store the animation frame ID
  const [silenceDetected, setSilenceDetected] = useState(false);
  const { t } = useTranslation('common');
  const setupMediaStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      const audioContext = new AudioContext();
      const audioStreamSource = audioContext.createMediaStreamSource(stream);
      const analyser = audioContext.createAnalyser();
      analyser.minDecibels = MIN_DECIBELS;
      audioStreamSource.connect(analyser);

      audioContextRef.current = audioContext;
      analyserRef.current = analyser;
      startSilenceDetection();
    } catch (error) {
      console.error('Error accessing microphone:', error);
      requestMicAccess();
    }
  };

  const startSilenceDetection = () => {
    const bufferLength = analyserRef.current?.frequencyBinCount || 0;
    const domainData = new Uint8Array(bufferLength);

    const detectSound = () => {
      analyserRef.current?.getByteFrequencyData(domainData);

      let detected = false;
      for (let i = 0; i < bufferLength; i++) {
        if (domainData[i] > 0) {
          detected = true;
          break;
        }
      }

      if (detected) {
        setSilenceDetected(false);
        if (silenceTimerRef.current) {
          clearTimeout(silenceTimerRef.current);
          silenceTimerRef.current = null;
        }
      } else if (!silenceTimerRef.current) {
        silenceTimerRef.current = setTimeout(() => {
          setSilenceDetected(true);
        }, SILENCE_DURATION);
      }

      animationFrameRef.current = window.requestAnimationFrame(detectSound); // Store the animation frame ID
    };

    animationFrameRef.current = window.requestAnimationFrame(detectSound); // Start the detection loop
  };

  const createWaveSurfer = () => {
    if (waveSurferRef.current) {
      waveSurferRef.current.destroy();
    }

    waveSurferRef.current = WaveSurfer.create({
      container: '#mic',
      waveColor: '#246BFD',
      height: isTabletOrMobile ? 300 : 400,
      width: '100%',
      barWidth: 4,
      barRadius: 10,
      cursorWidth: 0,
      autoplay: false,
      cursorColor: 'navy',
    });

    recordRef.current = waveSurferRef.current.registerPlugin(
      RecordPlugin.create({
        scrollingWaveform: false,
      })
    );

    //function to calc the time of recording
    // recordRef.current.on('record-progress', (time: number) => {
    //   const formattedTime = [
    //     Math.floor((time % 3600000) / 60000), // minutes
    //     Math.floor((time % 60000) / 1000), // seconds
    //   ]
    //     .map((v) => (v < 10 ? '0' + v : v))
    //     .join(':');
    //   setProgress(formattedTime);
    // });

    recordRef.current.on('record-end', () => {
      console.log('Recording ended');
      setIsRecording(false);
      stopSilenceDetection();
    });
  };

  useEffect(() => {
    createWaveSurfer();

    return () => {
      waveSurferRef.current?.destroy();
      audioContextRef.current?.close();
      stopSilenceDetection(); // Cleanup on component unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stopSilenceDetection = () => {
    if (silenceTimerRef.current) {
      clearTimeout(silenceTimerRef.current);
      silenceTimerRef.current = null;
    }
    if (animationFrameRef.current !== null) {
      cancelAnimationFrame(animationFrameRef.current); // Cancel the animation frame
      animationFrameRef.current = null; // Reset the animation frame ID
    }
  };

  const handleRecordClick = async () => {
    if (isRecording) {
      setIsRecording(false);
      recordRef.current.stopRecording();
      mediaRecorderRef.current?.stop();
      handleStopRecord();
    } else {
      try {
        await recordRef.current.startRecording();
        mediaRecorderRef.current?.start();
        await setupMediaStream();
        setIsRecording(true);
        handleRecord();
      } catch (error) {
        requestMicAccess();
      }
    }
  };

  return (
    <div className="w-full relative">
      {!isRecording && <div className={'w-full border border-b-primary-blue top-[35%] absolute'}></div>}
      <div id="mic" className="w-full mt-1"></div>
      <div
        className={'flex justify-start items-center flex-col cursor-pointer z-10 relative mt-0 md:mt-11'}
        onClick={() => handleRecordClick()}
      >
        <img src={RecordIcon} alt="record icon" />
        <CommonText
          className="font-customFont font-normal text-base text-third-gray mt-4"
          text={isRecording ? t('record.stopRecording') : t('record.startRecording')}
        />
      </div>

      {silenceDetected && (
        <CommonMicrophoneIssueModal
          isOpen={true}
          handleDiscard={() => console.log('discard')}
          handleFixMic={() => {
            recordRef.current.stopRecording();
            mediaRecorderRef.current?.stop();
            setSilenceDetected(false);
            stopSilenceDetection();
            setIsRecording(false);
            createWaveSurfer();
          }}
        />
      )}
    </div>
  );
};

export default WaveAnimation;

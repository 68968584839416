import { BookText, CircleHelp, Info, User } from 'lucide-react';
import { CommonText } from '../../components/CommonText';
import { useNavigate } from 'react-router-dom';
import { cn } from '../../utils/conditionalClasses';
import { locale } from '../../i18n';

const sections = () => [
  {
    title: locale.t('settings.myProfile'),
    secondTitle: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    logo: <User className="w-6 h-6" />,
    pathToNavigate: '/settings/profile',
  },
  {
    title: locale.t('settings.marketingPreferences'),
    secondTitle: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    logo: <Info className="w-6 h-6" />,
    pathToNavigate: '/settings/marketingPreferences',
  },
  {
    title: locale.t('settings.billingInformation'),
    secondTitle: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    logo: <BookText className="w-6 h-6" />,
    pathToNavigate: '/settings/billingInformation',
  },
  { isSeperator: true, title: 'Other' },
  {
    title: locale.t('settings.helpCenter'),
    secondTitle: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    logo: <CircleHelp className="w-6 h-6" />,
    pathToNavigate: '',
  },
];

const Settings = () => {
  const naviage = useNavigate();
  const sectionsList = sections();
  return (
    <div className="p-4 md:p-0">
      <CommonText className="text-primary-dark font-bold text-2xl mt-4 md:mt-0 flex justify-center md:justify-start" text={'Settings'} />
      {sectionsList.map((section, index) => {
        return (
          <>
            {section.isSeperator ? (
              <div className="mt-6 flex gap-2 items-center">
                <CommonText className="font-customFont font-semibold text-sm text-third-gray w-[4%]" text={section?.title} />
                <div className="border-t-[1px] border-primary-gray w-[92%]"></div>
              </div>
            ) : (
              <div
                className={cn('w-full h-42 flex gap-3 cursor-pointer', index === 0 ? 'mt-4 md:mt-10' : 'mt-4')}
                onClick={() => naviage(section?.pathToNavigate || '')}
              >
                <div className="w-[60px] h-[60px] rounded-full border-[1px] border-primary-gray flex justify-center items-center">
                  {section.logo}
                </div>
                <div className="flex flex-col text-left w-[calc(100%-60px)]">
                  <CommonText className="text-lg font-semibold font-customFont" text={section?.title} />
                  <CommonText className="text-sm font-medium font-customFont" text={section?.secondTitle} />
                </div>
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};

export default Settings;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { cn } from '../../utils/conditionalClasses';
import { CommonForm, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../components/CommonForm';
import { CommonInput } from '../../components/CommonInput';
import { CommonText } from '../../components/CommonText';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { InfoIcon } from 'lucide-react';
import { CommonButton } from '../../components/CommonButton';
import { useEffect, useState } from 'react';
import { useApi } from '../../api/useApi';
import CommonLoadingModal from '../../components/CommonLoadingModal';
import CommonConfirmationModal from '../../components/CommonConfirmationModal';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from '../../context/hooks';
import { selectAudio } from '../../context/selectors/AudioSelectors';
import { useLocation, useNavigate } from 'react-router-dom';
import { saveAudio } from '../../context/slices/AudioSlice';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { getReportDetailsSchema } from '../../utils/validators/ReportDetailsSchema';

type ReportDataType = z.infer<any>;
type ProfileResponseDataType = { message: string };

const ReportDetails = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { t } = useTranslation('common');

  const ReportDetailsSchema = getReportDetailsSchema();
  const form = useForm<z.infer<typeof ReportDetailsSchema>>({
    resolver: zodResolver(ReportDetailsSchema),
  });

  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { post } = useApi();

  const audio = useAppSelector(selectAudio);

  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const { control, setValue, watch } = form;

  const { language, sessionLength, sessionLayouts } = watch();

  const onSubmit = async (data: ReportDataType) => {
    if (audio?.audio) {
      setIsLoading(true);

      const formData = new FormData();
      formData.append('title', data.title);
      formData.append('patientidentifier', data.patientidentifier);
      formData.append('patientname', data.patientname);
      formData.append('language', `${data.language}`);
      formData.append('sessionLength', `${data.sessionLength}`);
      formData.append('sessionLayouts', JSON.stringify(data.sessionLayouts));
      formData.append('fileType', '1');
      formData.append('file', audio?.audio);

      const response: any = await post<ReportDataType, ProfileResponseDataType>('/sessions/create', formData as any, true, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response?.status === 200 && response?.data.isSuccess && response?.data?.data) {
        const sessionId = response?.data?.data?.id;
        setIsLoading(false);
        setIsConfirmed(true);
        setTimeout(() => {
          setIsConfirmed(false);
          //navigate(`/home/${pathname.includes('uploadRecord') ? 'uploadRecord' : 'voiceRecord'}/report/${sessionId}`);
          navigate('/library');
          // dispatch(saveAudio(null));
        }, 1500);
      } else {
        const message = response?.data?.messages?.length > 0 ? response?.data?.messages[0] : 'Something went wrong';
        enqueueSnackbar(message, {
          variant: 'error',
        });
        setIsLoading(false);
        setIsConfirmed(false);
      }
    }
  };

  useEffect(() => {
    if (!audio.audio) {
      navigate('/home/uploadRecord');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio?.audio]);

  const handleSubmit = form.handleSubmit(onSubmit);
  return (
    <div className="px-1 md:px-0">
      <CommonForm {...form}>
        <CommonText
          className="text-primary-dark font-bold text-2xl mt-[14px] md:mt-0 flex justify-center md:justify-start "
          text={t('report.reportDetails')}
        />
        <form onSubmit={handleSubmit}>
          <div className="mt-6">
            <FormField
              control={control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="font-semibold">{t('report.recordingName')}</FormLabel>
                  <FormControl>
                    <CommonInput placeholder={t('report.record000001')} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="mt-6">
            <FormField
              control={control}
              name="patientidentifier"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="font-semibold">{t('report.patientID')}</FormLabel>
                  <FormControl>
                    <CommonInput placeholder={'00000000'} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="mt-6">
            <FormField
              control={control}
              name="patientname"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="font-semibold">{t('report.patientName')}</FormLabel>
                  <FormControl>
                    <CommonInput placeholder={'John Doe'} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="mt-6 flex gap-2 items-center">
            <CommonText
              className="font-customFont font-semibold text-sm text-third-gray w-[30%] md:w-[8%]"
              text={t('report.reportStyling')}
            />
            <div className="border-t-[1px] border-primary-gray w-[70%] md:w-[92%]"></div>
          </div>
          <div className="mt-6">
            <FormField
              control={control}
              name="language"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="font-semibold">{t('report.reportLanguage')}</FormLabel>
                  <div className="flex gap-6">
                    <div
                      onClick={() => setValue('language', 0)}
                      className={cn(
                        'w-[99px] cursor-pointer h-[34px] border-[1px] border-primary-blue rounded-full flex justify-center items-center font-semibold text-sm font-customFont',
                        language === 0 ? 'bg-primary-blue text-white' : 'bg-white text-primary-blue'
                      )}
                    >
                      {t('report.english')}
                    </div>
                    <div
                      onClick={() => setValue('language', 1)}
                      className={cn(
                        'w-[99px] h-[34px] cursor-pointer border-[1px] border-primary-blue rounded-full flex justify-center items-center font-semibold text-sm font-customFont',
                        language === 1 ? 'bg-primary-blue text-white' : 'bg-white text-primary-blue'
                      )}
                    >
                      {t('report.grecee')}
                    </div>
                  </div>

                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="mt-6">
            <FormField
              control={control}
              name="sessionLength"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="font-semibold ">{t('report.reportLength')}</FormLabel>
                  <div className="flex gap-6">
                    <div
                      onClick={() => setValue('sessionLength', 0)}
                      className={cn(
                        'w-fit px-2 cursor-pointer h-[34px] border-[1px] border-primary-blue rounded-full flex justify-center items-center font-semibold text-sm font-customFont',
                        sessionLength === 0 ? 'bg-primary-blue text-white' : 'bg-white text-primary-blue'
                      )}
                    >
                      {t('report.overview')}
                    </div>
                    <div
                      onClick={() => setValue('sessionLength', 1)}
                      className={cn(
                        'w-[99px] h-[34px] cursor-pointer border-[1px] border-primary-blue rounded-full flex justify-center items-center font-semibold text-sm font-customFont',
                        sessionLength === 1 ? 'bg-primary-blue text-white' : 'bg-white text-primary-blue'
                      )}
                    >
                      {t('report.detailed')}
                    </div>
                  </div>

                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="mt-6">
            <FormField
              control={control}
              name="sessionLayouts"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="font-semibold flex gap-2 items-center">
                    {t('report.reportLayout')}
                    <div className="px-4 py-2 gap-2 flex bg-[#235DFF] bg-opacity-10 border-none rounded-md text-primary-blue">
                      <InfoIcon className="w-5 h-5" />
                      <CommonText
                        className="font-customFont font-normal text-sm "
                        text={isTabletOrMobile ? t('report.bothOptions') : t('report.chooseBoth')}
                      />
                    </div>
                  </FormLabel>
                  <div className="flex gap-6">
                    <div
                      onClick={() => {
                        if (sessionLayouts?.includes(0)) {
                          sessionLayouts?.length > 1 ? setValue('sessionLayouts', [1]) : setValue('sessionLayouts', []);
                        } else {
                          if (sessionLayouts)
                            sessionLayouts?.length > 0 ? setValue('sessionLayouts', [0, 1]) : setValue('sessionLayouts', [1]);
                          else setValue('sessionLayouts', [0]);
                        }
                      }}
                      className={cn(
                        'w-[120px] cursor-pointer h-[34px] border-[1px] border-primary-blue rounded-full flex justify-center items-center font-semibold text-sm font-customFont',
                        sessionLayouts?.some((layout) => layout === 0) ? 'bg-primary-blue text-white' : 'bg-white text-primary-blue'
                      )}
                    >
                      {t('report.paragraphs')}
                    </div>
                    <div
                      onClick={() => {
                        if (sessionLayouts?.includes(1)) {
                          sessionLayouts?.length > 1 ? setValue('sessionLayouts', [0]) : setValue('sessionLayouts', []);
                        } else {
                          if (sessionLayouts)
                            sessionLayouts?.length > 0 ? setValue('sessionLayouts', [0, 1]) : setValue('sessionLayouts', [1]);
                          else setValue('sessionLayouts', [1]);
                        }
                      }}
                      className={cn(
                        'w-[110px] h-[34px] cursor-pointer border-[1px] border-primary-blue rounded-full flex justify-center items-center font-semibold text-sm font-customFont',
                        sessionLayouts?.some((layout) => layout === 1) ? 'bg-primary-blue text-white' : 'bg-white text-primary-blue'
                      )}
                    >
                      {t('report.bulletPoints')}
                    </div>
                  </div>

                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="z-10 fixed bottom-0 md:static -ml-4 md:ml-0 bg-white mt-5 h-24 w-full md:mt-12 flex justify-center items-center">
            <CommonButton type={'submit'} className="font-customFont w-72">
              {t('voiceActions.generateReport')}
            </CommonButton>
          </div>
        </form>
      </CommonForm>
      <CommonLoadingModal primaryText={t('report.weAreOnIt')} secondaryText={t('report.newSession')} isLoading={isLoading} />
      <CommonConfirmationModal
        isConfirmed={isConfirmed}
        primaryText={t('modals.proccessinCompleted')}
        secondaryText={t('modals.reportReady')}
      />
    </div>
  );
};

export default ReportDetails;

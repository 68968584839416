/* eslint-disable @typescript-eslint/no-unused-vars */
import { useGetAccessToken } from '../../api/auth/actions/GetAccessToken';
import { useAppSelector } from '../../context/hooks';
import { selectAccessToken } from '../../context/selectors/AuthSelectors';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useApi } from '../../api/useApi';
import { CommonButton } from '../../components/CommonButton';
import { LoadingSpinner } from '../../components/CommonLoadingSpinner';
import LibraryRow from '../../components/library/LibraryRow';
import EmptyLibrary from '../../components/library/EmptyLibrary';
import HeaderLibrary from '../../components/library/HeaderLibrary';
import { ILibrary } from '../../interfaces/library';
import { useMediaQuery } from 'react-responsive';
import { cn } from '../../utils/conditionalClasses';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';

const Library = () => {
  const [rows, setRows] = useState<ILibrary[] | null>(null);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ searchValue: '', date: '' });
  const [hasMore, setHasMore] = useState(true);
  const { t } = useTranslation('common');
  const [showDeleteOption, setShowDeleteOption] = useState(false);

  const [allowSelecting, setAllowSelecting] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[] | null>(null);

  const libraryGridRef = useRef<HTMLDivElement | null>(null);

  const [debouncedInputValue, setDebouncedInputValue] = useState('');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter((prevState) => ({ ...prevState, searchValue: e?.target?.value }));
  };

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setDebouncedInputValue(filter.searchValue);
  //     setHasMore(true);
  //   }, 800);
  //   return () => clearTimeout(timeoutId);
  // }, [filter.searchValue]);

  useGetAccessToken();
  const accessToken = useAppSelector(selectAccessToken);
  const { get, deleteAction } = useApi();

  const getSessions = async (skip?: number) => {
    if (loading || !hasMore) return;
    setLoading(true);
    try {
      const response: any = debouncedInputValue
        ? await get(`/sessions/list?take=${10}&skip=${skip || 0}`, {
            patientName: debouncedInputValue,
            patientIdentifier: debouncedInputValue,
          })
        : await get(`/sessions/list?take=${10}&skip=${skip || 0}`);
      if (response?.data?.data) {
        const data = response?.data?.data;
        if (debouncedInputValue) {
          if (data?.length > 0) {
            if (!skip) setRows([...data]);
            else setRows((prevState) => [...(prevState || []), ...data]);
          } else {
            setRows([]);
            setHasMore(false);
          }
        } else {
          if (data?.length > 0) {
            if (!skip) setRows([...data]);
            else setRows((prevState) => [...(prevState || []), ...data]);
          } else setHasMore(false);
          if (skip && skip > data.length) {
            setHasMore(false);
          }
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteReports = async (e: React.MouseEvent) => {
    if (selectedRows && selectedRows?.length > 0) {
      const response = await deleteAction(`/sessions/delete`, { ids: selectedRows });
      if (response.status === 200) {
        setSelectedRows(null);
        setAllowSelecting(false);
        if (accessToken) {
          setSelectedRows(null);
          enqueueSnackbar(t('modals.reportDeleted'), { variant: 'success' });
          getSessions();
        }
      }
    }
    setShowDeleteOption(false);
  };

  useEffect(() => {
    if (accessToken) getSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, debouncedInputValue]);

  const handleScroll = () => {
    if (libraryGridRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = libraryGridRef.current;
      if (scrollTop + clientHeight >= scrollHeight && !loading) {
        setShowLoadMoreButton(true);
      } else setShowLoadMoreButton(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const container = libraryGridRef.current;
      if (container) {
        container.addEventListener('scroll', handleScroll);
      }
    }, 0);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const container = libraryGridRef.current;
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const showElement = (rows && rows.length > 0) || !!debouncedInputValue;

  return (
    <div className="p-4 md:p-0 md:h-full mt-4 md:mt-0" style={isTabletOrMobile ? { height: 'calc(100% - 100px)', overflow: 'hidden' } : {}}>
      <HeaderLibrary
        allowSelecting={allowSelecting}
        setAllowSelecting={setAllowSelecting}
        searchValue={filter.searchValue}
        showElement={showElement}
        handleInputChange={handleInputChange}
        handleDeleteReports={handleDeleteReports}
        setShowDeleteOption={setShowDeleteOption}
        showDeleteOption={showDeleteOption}
        setSelectedRows={setSelectedRows}
      />
      <div ref={libraryGridRef} className="max-h-[700px] h-full overflow-auto pb-[160px] md:pb-0">
        {showElement ? (
          rows?.map((row) => {
            return <LibraryRow setSelectedRows={setSelectedRows} allowSelecting={allowSelecting} selectedRows={selectedRows} row={row} />;
          })
        ) : (
          <>
            {!loading ? (
              <EmptyLibrary />
            ) : (
              <div className="h-full md:h-[700px] w-full flex justify-center items-center">
                <LoadingSpinner className="w-14 h-14 text-primary-blue" />
              </div>
            )}
          </>
        )}
      </div>

      {showLoadMoreButton && hasMore && (
        <div
          onClick={() => {
            getSessions(rows?.length);
            setShowLoadMoreButton(false);
          }}
          className={cn(
            ' md:px-0 md:absolute  md:bottom-7 md:left-1/2 z-10',
            isTabletOrMobile ? 'fixed w-full -ml-4 justify-center bg-white h-24 flex items-center bottom-[0px]' : ''
          )}
        >
          <CommonButton className={cn(isTabletOrMobile ? 'w-[calc(100%-20px)]' : '')} variant={'default'}>
            {loading ? <LoadingSpinner className="w-8 h-8 text-white" /> : t('loadMore')}
          </CommonButton>
        </div>
      )}
    </div>
  );
};

export default Library;

import { CommonButton } from '../../../src/components/CommonButton';
import { CommonText } from '../../../src/components/CommonText';
import { FormField, FormItem } from '../../../src/components/CommonForm';
import { useFormContext, useWatch } from 'react-hook-form';
import { CommonMultiSelect } from '../CommonMultiSelect';
import { useAppSelector } from '../../context/hooks';
import { selectSpecialities } from '../../context/selectors/ConstantsSelectors';
import { capitalCase } from 'change-case';
import { useTranslation } from 'react-i18next';

interface DoctorSpecialtyProps {
  isFormTooTall?: boolean;
  onNext: () => void;
}

export const DoctorSpecialty: React.FC<DoctorSpecialtyProps> = ({ isFormTooTall, onNext }) => {
  const { control } = useFormContext();
  const { t } = useTranslation('common');
  const [specialtyIds] = useWatch({ name: ['specialtyIds'], control });

  const specialities =
    useAppSelector(selectSpecialities)?.map((option) => ({
      id: option.id,
      value: capitalCase(option.value),
    })) || [];

  return (
    <>
      <div className="flex flex-col gap-2">
        <CommonText className="text-primary-dark font-bold text-3xl" text={t('profileInfo.knowBetter')} />
        <CommonText className="text-secondary-dark text-lg" text={t('profileInfo.selectSpecialty')} />
      </div>
      <div className="flex flex-col gap-2 mb-12">
        <FormField
          control={control}
          name="specialtyIds"
          render={({ field }) => (
            <FormItem>
              <CommonMultiSelect single maxCount={2} defaultValue={field.value} options={specialities} onValueChange={field.onChange} />
            </FormItem>
          )}
        />
        {specialtyIds?.length > 0 && (
          <div className={`${isFormTooTall ? 'flex flex-col fixed bottom-0 bg-white pb-8 gap-4' : 'block mt-8'}`}>
            {isFormTooTall && <div className="bg-border-gray h-0.5" />}
            <CommonButton onClick={onNext}>{t('continue')}</CommonButton>
          </div>
        )}
      </div>
    </>
  );
};

import { useAuth0 } from '@auth0/auth0-react';
import { cn } from '../../utils/conditionalClasses';
import { CircleHelp, LogOut } from 'lucide-react';
import { locale } from '../../i18n';
import { useTranslation } from 'react-i18next';

type Props = {
  toggleSubMenu: (navId: string) => void;
};

const footerMenu = () => [
  {
    navId: 'nav_1',
    icon: <CircleHelp className="text-primary-dark" width={'20px'} />,
    name: locale.t('nav.help'),
  },
  {
    navId: 'nav_2',
    icon: <LogOut className="text-primary-error" width={'20px'} />,
    name: locale.t('nav.logOutAccount'),
  },
];

const FooterMenu: React.FC<Props> = ({ toggleSubMenu }) => {
  const { logout } = useAuth0();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();
  const footer = footerMenu();

  return (
    <div className="mb-8">
      {footer.map((menu: any, index: number) => (
        <div
          key={index}
          className={cn(
            'w-full flex rounded-[8px] justify-start items-center h-[38px] font-normal gap-3 text-sm cursor-pointer',
            index === 1 ? 'text-primary-error' : 'text-primary-dark'
          )}
          onClick={() => {
            if (index === 1)
              logout({
                logoutParams: {
                  returnTo: window.location.origin + '/welcome',
                },
              });
          }}
        >
          {menu?.icon}
          {menu.name}
        </div>
      ))}
    </div>
  );
};

export default FooterMenu;

import React from 'react';
import { CommonDialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogOverlay, DialogTitle } from './CommonDialog';
import { CommonButton } from './CommonButton';

interface Props {
  title: string;
  question: string;
  stopText: string;
  continueText: string;
  stopAction?: (e: React.MouseEvent) => void;
  continueAction?: () => void;
  isOpen: boolean;
}

const CommonActionModal: React.FC<Props> = ({ isOpen, title, question, stopAction, stopText, continueText, continueAction }) => {
  return (
    <CommonDialog open={isOpen}>
      <DialogOverlay
        onClick={(e) => {
          continueAction && continueAction();
          e.preventDefault();
        }}
      >
        <DialogContent className="sm:max-w-[425px] bg-white">
          <DialogHeader>
            <DialogTitle className="font-customFont flex justify-center font-bold text-2xl text-primary-error">{title}</DialogTitle>
          </DialogHeader>
          <div className="font-customFont py-4 flex justify-center font-medium text-xl text-center text-primary-dark">{question}</div>
          <DialogFooter className="flex !justify-center items-center gap-6">
            <DialogClose asChild onClick={() => continueAction && continueAction()}>
              <CommonButton className="font-customFont w-40 h-12 font-bold">{continueText}</CommonButton>
            </DialogClose>
            <DialogClose asChild>
              <CommonButton className="font-customFont w-40 h-12 font-bold bg-primary-error" onClick={(e) => stopAction && stopAction(e)}>
                {stopText}
              </CommonButton>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </DialogOverlay>
    </CommonDialog>
  );
};

export default CommonActionModal;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAppSelector } from '../../context/hooks';
import { useGetAccessToken } from '../../api/auth/actions/GetAccessToken';
import { useApi } from '../../api/useApi';
import { useEffect, useState } from 'react';
import { selectAccessToken } from '../../context/selectors/AuthSelectors';
import Tiptap from '../editor/Tiptap';
import { useNavigate, useParams } from 'react-router-dom';
import { CommonButton } from '../../components/CommonButton';
import { enqueueSnackbar } from 'notistack';
import CommonDiscardDialog from '../../components/CommonDiscardDialog';

import CommonActionModal from '../../components/CommonActionModal';
import CommonConfirmationModal from '../../components/CommonConfirmationModal';
import ReportHeader from './ReportHeader';
import { useTranslation } from 'react-i18next';

const ReportContent = () => {
  const { id = '' } = useParams();
  const accessToken = useAppSelector(selectAccessToken);
  const [sessionData, setSessionData] = useState<any>(null);
  const [patientName, setPatientname] = useState<string | null>(null);
  const [content, setContent] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isDiscarded, setIsDiscarded] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isContentAvailable, setIsContentAvailable] = useState(false);
  const [copyText, setCopyText] = useState('');

  const { t, i18n } = useTranslation('common');

  const navigate = useNavigate();
  const { get, deleteAction, put } = useApi();

  const getNotificationsBasedSession = async (ownerId: string) => {
    try {
      const response: any = await get(`/notifications/list?ownerId=${ownerId}`);
      if (response?.data?.data) {
        const data = response?.data?.data;
        if (data?.length > 0) {
          return data;
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useGetAccessToken();
  useEffect(() => {
    setIsContentAvailable(false);
    const getSession = async () => {
      try {
        const response: any = await get(`/sessions/get?id=${id}`);
        if (response?.data?.data) {
          const data = response?.data?.data;
          setSessionData(data);
          setPatientname(data.patientName);
          if (data?.sessionContext) {
            if (data?.sessionContext.includes("It seems like you've a sample audio test recording")) {
              if (i18n.language === 'en') setContent(data?.sessionContext);
              else if (i18n.language === 'gr') setContent(t('report.reportError'));
            } else setContent(data?.sessionContext);
          } else {
            setContent(t('report.reportNotgenerated'));
          }

          setIsContentAvailable(true);
          const notificationsBasedOnSessions = await getNotificationsBasedSession(id);

          if (notificationsBasedOnSessions.length > 0) {
            notificationsBasedOnSessions.forEach(async (notification: any) => {
              if (!notification?.isRead) {
                await put('/notifications/update', {
                  id: notification.id,
                  isRead: true,
                });
              }
              if (!notification?.isViewd) {
                await put('/notifications/viewed', {
                  ids: [notification.id],
                });
              }
            });
          }
        }
      } catch (e) {
        console.error(e);
      }
    };

    if (accessToken && id) {
      getSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, accessToken]);

  const handleChangeContent = (value: string) => {
    setContent(value);
  };

  const handleCopyReport = async () => {
    try {
      await navigator.clipboard.writeText(copyText || '');
      enqueueSnackbar(t('modals.copiedReport'), { variant: 'success' });
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const handleUpdateReport = async () => {
    try {
      const getSession: any = await get(`/sessions/get?id=${id}`);
      if (getSession?.data?.data) {
        const sessionData = getSession.data.data;
        const response: any = await put(`/sessions/update`, {
          id: sessionData?.id,
          title: sessionData?.title,
          patientName: sessionData?.patientName,
          patientIdentifier: sessionData?.patientIdentifier,
          sessionContext: content,
          sessionLayouts: sessionData?.sessionLayouts,
        });
        if (response?.data?.isSuccess === true) {
          setIsEditMode(false);
          enqueueSnackbar(t('modals.reportUpdated'), { variant: 'success' });
        } else {
          enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
      }
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const handleDiscard = () => {
    setIsDiscarded(true);
    setIsContentAvailable(false);
    setContent(sessionData?.sessionContext ? sessionData?.sessionContext : t('report.reportNotgenerated'));
    setTimeout(() => {
      setIsContentAvailable(true);
      setIsDiscarded(false);
      setIsEditMode(false);
    }, 2500);
  };

  const handleDeleteReport = async (e: React.MouseEvent) => {
    const response = await deleteAction(`/sessions/delete`, { ids: [id] });
    setIsOpenDeleteDialog(false);
    if (response.status === 200 && response.data) {
      setIsDeleted(true);
      enqueueSnackbar(t('modals.reportDeleted'), { variant: 'success' });
      setTimeout(() => {
        setIsDeleted(false);
        navigate('/library');
      }, 2500);
    }
    e.stopPropagation();
  };

  const handleRegenerateReport = async () => {
    const response: any = await put(`/sessions/regenerate-context`, { id });

    setIsGenerating(false);
    if (response?.data.isSuccess && response?.data?.data) {
      enqueueSnackbar(t('modals.regeneratedReport'), { variant: 'success' });
      setIsContentAvailable(false);
      setContent(response?.data?.data?.sessionContext || '');
      setTimeout(() => {
        setIsContentAvailable(true);
      }, 2500);
    } else {
      enqueueSnackbar(response?.data?.messages?.length > 0 ? response?.data?.messages[0] : 'Something went wrong', {
        variant: 'error',
      });
    }
  };

  return (
    <div className="mt-4 md:mt-0 overflow-hidden h-[calc(100%-100px)] md:h-fit">
      <ReportHeader
        patientName={patientName}
        setIsGenerating={setIsGenerating}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        setIsOpenDeleteDialog={setIsOpenDeleteDialog}
      />
      <div>
        <Tiptap
          isContentAvailable={isContentAvailable}
          isDiscarded={isDiscarded}
          handleChangeContent={handleChangeContent}
          isEditMode={isEditMode}
          content={content}
          setCopyText={setCopyText}
        />
      </div>
      {isEditMode ? (
        <div className="z-10 fixed bottom-0 flex items-center md:relative w-full md:mt-16 bg-white h-24 -ml-4 md:ml-0">
          <CommonDiscardDialog
            primaryDescardedMessage={t('modals.reportDiscarded')}
            secondaryDescardedMessage={t('modals.startOver')}
            saveButtonName={t('voiceActions.saveReport')}
            isDiscarded={isDiscarded}
            handleDiscard={handleDiscard}
            handleSave={handleUpdateReport}
          />
        </div>
      ) : (
        <div className="z-10 fixed bottom-0 flex items-center md:relative w-full md:mt-12 bg-white h-24 -ml-4 md:ml-0 md:gap-8 justify-around md:justify-center">
          <CommonButton onClick={() => navigate('/library')} className="w-[45%] md:w-56" variant={'secondary'}>
            {t('voiceActions.backLibrary')}
          </CommonButton>
          <CommonButton onClick={handleCopyReport} className="w-[45%] md:w-56">
            {t('voiceActions.copyReport')}
          </CommonButton>
        </div>
      )}
      <div>
        <CommonActionModal
          isOpen={isOpenDeleteDialog}
          continueText={t('modals.continueEditing')}
          question={t('modals.deleteConfirmation')}
          stopText={t('modals.confirmDelete')}
          title={t('modals.deleteReport')}
          continueAction={() => setIsOpenDeleteDialog(false)}
          stopAction={handleDeleteReport}
        />
        <CommonConfirmationModal isConfirmed={isDeleted} primaryText={t('modals.deletedReport')} secondaryText={t('modals.startOver')} />
      </div>

      <div>
        <CommonActionModal
          isOpen={isGenerating}
          continueText={t('modals.continueEditing')}
          question={t('modals.regenerateDescription')}
          stopText={t('modals.regenerate')}
          title={t('modals.regenerateReport')}
          continueAction={() => setIsGenerating(false)}
          stopAction={handleRegenerateReport}
        />
      </div>
    </div>
  );
};

export default ReportContent;

import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';

export async function convertFilesToMP3(webmBlob: Blob) {
  const ffmpeg = new FFmpeg();
  if (ffmpeg.load) {
    await ffmpeg.load();
    const webmFileName = 'input.webm';
    const mp3FileName = 'sound.mp3';

    await ffmpeg.writeFile('input.webm', await fetchFile(webmBlob));

    await ffmpeg.exec(['-i', webmFileName, mp3FileName]);
    const fileData = await ffmpeg.readFile(mp3FileName);
    const data = new Uint8Array(fileData as ArrayBuffer);
    const mp3File = new File([data.buffer], mp3FileName, { type: 'audio/mp3' });

    return mp3File;
  }
  return null;
}

export const isMp3File = (file: File): boolean => {
  if (file.type === 'audio/mpeg') {
    return true;
  }

  const fileExtension = file.name.split('.').pop()?.toLowerCase();
  if (fileExtension === 'mp3') {
    return true;
  }

  return false;
};

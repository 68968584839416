export function formatDateTime(dateTimeStr: string): { date: string; time: string } {
  const utcDate = new Date(dateTimeStr);

  const timezoneOffset = utcDate.getTimezoneOffset() * 60 * 1000;
  const localDate = new Date(utcDate.getTime() - timezoneOffset);

  const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  const timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: true };

  const formattedDate: string = localDate.toLocaleDateString('en-US', dateOptions);
  const formattedTime: string = localDate.toLocaleTimeString('en-US', timeOptions);

  return {
    date: formattedDate || '',
    time: formattedTime || '',
  };
}

import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useCreateAxiosAuth, useCreateAxiosWithoutAuth } from './ApiFactory';

export const useApi = () => {
  const axiosAuth: AxiosInstance = useCreateAxiosAuth();
  const axiosWithoutAuth: AxiosInstance = useCreateAxiosWithoutAuth();

  const getAxiosInstance = (useAuth: boolean): AxiosInstance => {
    if (useAuth) {
      return axiosAuth;
    } else {
      return axiosWithoutAuth;
    }
  };

  const get = async <D, T>(
    endpoint: string,
    params?: { [key in string]: string | number | boolean | undefined },
    config?: AxiosRequestConfig<D>
  ) =>
    await getAxiosInstance(true).get<T, { data: T }>(endpoint, {
      params,
      headers: config?.headers,
    });

  const post = async <D, T>(endpoint: string, request: D, useAuth = true, config?: AxiosRequestConfig<D>) =>
    await getAxiosInstance(useAuth).post<
      T,
      {
        data: T;
      }
    >(endpoint, request, {
      params: config?.params,
      headers: config?.headers,
    });

  const patch = async <D, T>(endpoint: string, data: D) => await getAxiosInstance(true).patch<T, { data: T }>(endpoint, data);

  const put = async <D, T>(endpoint: string, data: D) => await getAxiosInstance(true).put<T, { data: T }>(endpoint, data);

  const deleteAction = async <D, T>(endpoint: string, data?: D) =>
    await getAxiosInstance(true).delete<{ data: T }>(endpoint, {
      data,
    });

  return {
    get,
    post,
    patch,
    put,
    deleteAction,
  };
};

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type Specialities = { id: string; value: string }[] | null;

interface ConstantsState {
  specialities: Specialities;
}

const initialState: ConstantsState = {
  specialities: null,
};

export const constantsSlice = createSlice({
  name: 'constants',
  initialState,
  reducers: {
    saveSpecialities: (state, action: PayloadAction<Specialities>) => {
      state.specialities = action.payload;
    },
  },
});

export const { saveSpecialities } = constantsSlice.actions;
export const constantsReducer = constantsSlice.reducer;

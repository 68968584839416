import * as React from 'react';
import { CheckIcon, XCircle, ChevronDown, XIcon } from 'lucide-react';
import { cn } from '../utils/conditionalClasses';
import { CommonSeparator } from './CommonSeparator';
import { CommonButton } from './CommonButton';
import { CommonBadge } from './CommonBadge';
import { CommonPopover, PopoverContent, PopoverTrigger } from './CommonPopover';
import { CommonCommand, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator } from './CommonCommand';
import { useTranslation } from 'react-i18next';

interface MultiSelectProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  options: {
    value: string;
    id: string;
    icon?: React.ComponentType<{ className?: string }>;
  }[];

  onValueChange: (id: string[]) => void;

  defaultValue?: string[];

  /**
   * Placeholder text to be displayed when no values are selected.
   * Optional, defaults to "Select options".
   */
  placeholder?: string;

  /**
   * If you want to make the select singleSelect
   */
  single?: boolean;

  /**
   * Animation duration in seconds for the visual effects (e.g., bouncing badges).
   * Optional, defaults to 0 (no animation).
   */
  animation?: number;

  /**
   * Maximum number of items to display. Extra selected items will be summarized.
   * Optional, defaults to 3.
   */
  maxCount?: number;

  /**
   * The modality of the popover. When set to true, interaction with outside elements
   * will be disabled and only popover content will be visible to screen readers.
   * Optional, defaults to false.
   */
  modalPopover?: boolean;

  /**
   * If true, renders the multi-select component as a child of another component.
   * Optional, defaults to false.
   */
  asChild?: boolean;

  /**
   * Additional class names to apply custom styles to the multi-select component.
   * Optional, can be used to add custom styles.
   */
  className?: string;
}

export const CommonMultiSelect = React.forwardRef<HTMLButtonElement, MultiSelectProps>(
  (
    {
      options,
      single = false,
      onValueChange,
      defaultValue = [],
      placeholder,
      animation = 0,
      maxCount = 3,
      modalPopover = false,
      asChild = false,
      className,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation('common');
    const placeholderField = placeholder ? placeholder : t('profileInfo.selectYourCoreSpeciality');
    const [selectedValues, setSelectedValues] = React.useState<string[]>(defaultValue);
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    const multiSelectField = document.getElementById('MultiSelect_ID');
    var widthSelectField = multiSelectField ? `${multiSelectField?.offsetWidth}px` : '0px';

    React.useEffect(() => {
      if (JSON.stringify(selectedValues) !== JSON.stringify(defaultValue)) {
        setSelectedValues(selectedValues);
      }
    }, [defaultValue, selectedValues]);

    const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        setIsPopoverOpen(true);
      } else if (event.key === 'Backspace' && !event.currentTarget.id) {
        const newSelectedValues = [...selectedValues];
        newSelectedValues.pop();
        setSelectedValues(newSelectedValues);
        onValueChange(newSelectedValues);
      }
    };

    const toggleOption = (id: string) => {
      const newSelectedValues = selectedValues.includes(id) ? selectedValues.filter((v) => v !== id) : [...selectedValues, id];
      setSelectedValues(newSelectedValues);
      onValueChange(newSelectedValues);
    };

    const handleClear = () => {
      setSelectedValues([]);
      onValueChange([]);
    };

    const handleTogglePopover = () => {
      setIsPopoverOpen((prev) => !prev);
    };

    const clearExtraOptions = () => {
      const newSelectedValues = selectedValues.slice(0, maxCount);
      setSelectedValues(newSelectedValues);
      onValueChange(newSelectedValues);
    };

    const toggleAll = () => {
      if (selectedValues.length === options.length) {
        handleClear();
      } else {
        const allValues = options.map((option) => option.id);
        setSelectedValues(allValues);
        onValueChange(allValues);
      }
    };

    return (
      <div id="MultiSelect_ID">
        <CommonPopover open={isPopoverOpen} onOpenChange={setIsPopoverOpen} modal={modalPopover}>
          <PopoverTrigger asChild>
            <CommonButton
              ref={ref}
              {...props}
              onClick={handleTogglePopover}
              className="flex w-full p-2 rounded-md h-auto items-center justify-between bg-inherit hover:bg-inherit', border-primary-gray bg-white border-[1px] min-h-16"
            >
              {selectedValues.length > 0 ? (
                <div className="flex justify-between items-center w-full">
                  <div className="flex flex-wrap items-center ">
                    {selectedValues.slice(0, maxCount).map((id) => {
                      const option = options.find((o) => o.id === id);
                      const IconComponent = option?.icon;
                      return (
                        <CommonBadge key={id} className={'h-12 bg-primary-gray rounded-md min-w-28'}>
                          {IconComponent && <IconComponent className="h-4 w-4 mr-2" />}
                          <div className="text-primary-dark font-normal text-base">{option?.value}</div>
                          <XCircle
                            className="ml-2 h-4 w-4 cursor-pointer text-primary-dark"
                            onClick={(event) => {
                              event.stopPropagation();
                              toggleOption(id);
                            }}
                          />
                        </CommonBadge>
                      );
                    })}
                    {selectedValues.length > maxCount && (
                      <CommonBadge
                        className={cn('h-12 min-w-28 bg-primary-gray rounded-md border-none text-sm text-primary-dark justify-between')}
                        style={{ animationDuration: `${animation}s` }}
                      >
                        {`+ ${selectedValues.length - maxCount} more`}
                        <XCircle
                          className="ml-2 h-4 w-4 cursor-pointe"
                          onClick={(event) => {
                            event.stopPropagation();
                            clearExtraOptions();
                          }}
                        />
                      </CommonBadge>
                    )}
                  </div>
                  <div className="flex items-center justify-between">
                    <XIcon
                      className="h-4 mx-2 cursor-pointer text-primary-dark"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleClear();
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-between w-full mx-auto">
                  <span className="text-lg font-light mx-3 text-secondary-gray">{placeholderField}</span>
                  <ChevronDown className="h-4 cursor-pointer text-muted-foreground mx-2" />
                </div>
              )}
            </CommonButton>
          </PopoverTrigger>
          <PopoverContent
            className={cn(
              `!min-w-[${widthSelectField ? widthSelectField : '384px'}] !w-[${widthSelectField ? widthSelectField : '384px'}] p-0 border-none`
            )}
            align="start"
            onEscapeKeyDown={() => setIsPopoverOpen(false)}
          >
            <CommonCommand className="bg-white">
              <CommandInput placeholder={t('search')} onKeyDown={handleInputKeyDown} />
              <CommandList
                className={cn(
                  `!min-w-[${widthSelectField ? widthSelectField : '384px'}] !w-[${widthSelectField ? widthSelectField : '384px'}]`
                )}
              >
                <CommandEmpty>No results found.</CommandEmpty>
                <CommandGroup className="w-full border-none">
                  {!single && (
                    <CommandItem key="all" onSelect={toggleAll} className="cursor-pointer text-lg font-normal flex flex-col w-fu">
                      <div className="flex w-full items-center">
                        <div
                          className={cn(
                            'mr-4 flex h-6 w-6 items-center justify-center rounded-md border-[3px] border-primary text-primary-blue',
                            selectedValues.length === options.length ? 'text-primary-blue' : 'opacity-50 [&_svg]:invisible'
                          )}
                        >
                          <CheckIcon color="#246BFD" className="h-6 w-6 rounded-md" />
                        </div>
                        <div className="text-primary-dark font-medium">{t('select')}</div>
                      </div>
                      <CommonSeparator className="bg-primary-gray mt-3" orientation={'horizontal'} />
                    </CommandItem>
                  )}
                  {options.map((option, index) => {
                    const isSelected = selectedValues.includes(option.id);
                    const disableAllOtherOptions = single && !isSelected && selectedValues?.length > 0;
                    return (
                      <CommandItem
                        key={option.id}
                        onSelect={() => !disableAllOtherOptions && toggleOption(option.id)}
                        className="cursor-pointer text-lg font-light flex flex-col"
                      >
                        <div className="flex items-center w-full">
                          <div
                            className={cn(
                              'mr-4 flex h-6 w-6 items-center justify-center rounded-md border-[3px] border-primary text-primary-blue',
                              isSelected
                                ? 'text-primary-blue'
                                : disableAllOtherOptions
                                  ? 'opacity-10 [&_svg]:invisible'
                                  : 'opacity-50 [&_svg]:invisible'
                            )}
                          >
                            <CheckIcon color="#246BFD" className="h-4 w-4" />
                          </div>
                          {option.icon && <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
                          <div className={cn('text-primary-dark font-medium', disableAllOtherOptions && 'text-secondary-gray')}>
                            {option.value}
                          </div>
                        </div>

                        {index !== options.length - 1 ? (
                          <CommonSeparator className="bg-primary-gray mt-3" orientation={'horizontal'} />
                        ) : null}
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
                <CommandSeparator />
              </CommandList>
            </CommonCommand>
          </PopoverContent>
        </CommonPopover>
      </div>
    );
  }
);

CommonMultiSelect.displayName = 'CommonMultiSelect';

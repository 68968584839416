export const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  let formatedMinutes = '';

  if (minutes < 9) {
    formatedMinutes = `0${minutes}`;
  } else {
    formatedMinutes = `${minutes}`;
  }

  const seconds = Math.floor(time % 60);
  return `${formatedMinutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

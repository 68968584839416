import * as signalR from '@microsoft/signalr';
const URL = 'https://healthtech.azurewebsites.net/notificationhub'; //or whatever your backend port is

class Connector {
  private connection!: signalR.HubConnection | null;
  public events: (getNotificationCount: (notificationsCount: number) => void, getNewNotification: (newNotification: any) => void) => void;
  static instance: Connector;

  constructor(token: string | null) {
    if (token) {
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(URL, {
          accessTokenFactory: () => token,
          withCredentials: true,
        })
        .build();
      this.connection.start().catch((err) => document.write(err));
    }

    this.events = (getNotificationCount, getNewNotification) => {
      if (this.connection) {
        this.connection.on('NotificationCount', (notificationsCountFromHub) => {
          getNotificationCount(notificationsCountFromHub); // Call the function to handle notification count
        });
        this.connection.on('NewNotification', (newNotificationFromHub) => {
          getNewNotification(newNotificationFromHub); // Call the function to handle new notifications
        });
      }
    };
  }

  public static getInstance(token: string | null): Connector {
    Connector.instance = new Connector(token);
    return Connector.instance;
  }
}

export default Connector.getInstance;

import { CommonButton } from '../../../src/components/CommonButton';
import { CommonText } from '../../../src/components/CommonText';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../../src/components/CommonForm';
import { CommonInput } from '../../../src/components/CommonInput';
import { CommonSelect, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../src/components/CommonSelect';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { CommonRadioGroup, RadioGroupItem } from '../CommonRadioGroup';
import { useFormContext, useWatch } from 'react-hook-form';
import { CommonPopover, PopoverContent, PopoverTrigger } from '../CommonPopover';
import { CalendarIcon } from 'lucide-react';
import { CommonYearCalendar } from '../CommonYearCalendar';
import { cn } from '../../utils/conditionalClasses';
import { format } from 'date-fns';

interface ProfileInfoProps {
  isFormTooTall?: boolean;
  onNext: () => void;
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ isFormTooTall, onNext }) => {
  const { control, setValue } = useFormContext();

  const [birthdate] = useWatch({ name: ['birthdate'], control });
  const { t } = useTranslation('common');
  const [updatesExpanded, setUpdatesExpanded] = useState(false);

  const toggleUpdatesInfo = () => {
    setUpdatesExpanded(!updatesExpanded);
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        <CommonText className="text-primary-dark font-bold text-3xl" text={t('profileInfo.completeProfile')} />
        <CommonText className="text-secondary-dark text-lg" text={t('profileInfo.aboutThere')} />
      </div>
      <div className="flex flex-col gap-2 mb-12">
        <FormField
          control={control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-semibold">{t('profileInfo.title')}</FormLabel>
              <CommonSelect onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder={t('profileInfo.selectTitle')} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="Dr">{t('profileInfo.dr')}</SelectItem>
                  <SelectItem value="Drs">{t('profileInfo.drs')}</SelectItem>
                </SelectContent>
              </CommonSelect>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="firstName"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-semibold">{t('profileInfo.firstName')}</FormLabel>
              <FormControl>
                <CommonInput placeholder={t('profileInfo.firstName')} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="lastName"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-semibold">{t('profileInfo.lastName')}</FormLabel>
              <FormControl>
                <CommonInput placeholder={t('profileInfo.lastName')} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="phoneNumber"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-semibold">{t('profileInfo.phoneNumber')}</FormLabel>
              <FormControl>
                <CommonInput placeholder={t('profileInfo.phoneNumber')} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="gender"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-semibold">{t('profileInfo.gender')}</FormLabel>
              <CommonSelect onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder={t('profileInfo.selectGender')} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="Female">{t('profileInfo.female')}</SelectItem>
                  <SelectItem value="Male">{t('profileInfo.male')}</SelectItem>
                </SelectContent>
              </CommonSelect>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="birthdate"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel className="font-semibold">{t('profileInfo.birthdate')}</FormLabel>
              {/* @ts-ignore */}
              <CommonPopover>
                <PopoverTrigger asChild>
                  <CommonButton
                    variant={'outline'}
                    className={cn(
                      'w-full h-16 justify-start rounded-md text-secondary-dark text-left text-lg font-medium font-customFont bg-input-gray pl-4'
                    )}
                  >
                    <CalendarIcon className="mr-2 h-5 w-5" />
                    {birthdate ? format(birthdate, 'PPP') : <span>{t('pickADate')}</span>}
                  </CommonButton>
                </PopoverTrigger>
                <PopoverContent align="start" className=" w-auto p-4 border-none bg-white">
                  <CommonYearCalendar
                    mode="single"
                    captionLayout="dropdown-buttons"
                    selected={birthdate}
                    onSelect={(e) => setValue('birthdate', e)}
                    fromYear={1960}
                    toYear={2050}
                  />
                </PopoverContent>
              </CommonPopover>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex flex-col gap-3">
          {!updatesExpanded ? (
            <CommonText
              text={
                <p>
                  {t('profileInfo.receiveUpdates')}{' '}
                  <button onClick={toggleUpdatesInfo} className="text-primary-blue">
                    {t('profileInfo.seeMore')}
                  </button>
                </p>
              }
            />
          ) : (
            <CommonText
              text={
                <p>
                  {t('profileInfo.largeUpdates')}{' '}
                  <button onClick={toggleUpdatesInfo} className="text-primary-blue">
                    {t('profileInfo.seeLess')}
                  </button>
                </p>
              }
            />
          )}
          <FormField
            control={control}
            name="emailNotifications"
            render={({ field }) => (
              <div className="flex flex-col gap-2">
                <FormItem className="flex flex-row items-center">
                  <FormLabel className="font-semibold flex-1 pt-2">{t('profileInfo.email')}</FormLabel>
                  <FormControl className="flex-1">
                    <CommonRadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex flex-row items-center gap-5"
                    >
                      <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={'yes'} />
                        </FormControl>
                        <FormLabel className="font-medium">{t('yes')}</FormLabel>
                      </FormItem>
                      <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={'no'} />
                        </FormControl>
                        <FormLabel className="font-medium">{t('no')}</FormLabel>
                      </FormItem>
                    </CommonRadioGroup>
                  </FormControl>
                  <div className="flex-1" />
                </FormItem>
                <FormMessage />
              </div>
            )}
          />
          <FormField
            control={control}
            name="smsNotifications"
            render={({ field }) => (
              <div className="flex flex-col gap-2">
                <FormItem className="flex flex-row items-center">
                  <FormLabel className="font-semibold flex-1 mt-2">{t('profileInfo.sms')}</FormLabel>
                  <FormControl className="flex-1">
                    <CommonRadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex flex-row items-center gap-5"
                    >
                      <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={'yes'} />
                        </FormControl>
                        <FormLabel className="font-medium">{t('yes')}</FormLabel>
                      </FormItem>
                      <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={'no'} />
                        </FormControl>
                        <FormLabel className="font-medium">{t('no')}</FormLabel>
                      </FormItem>
                    </CommonRadioGroup>
                  </FormControl>
                  <div className="flex-1" />
                </FormItem>
                <FormMessage />
              </div>
            )}
          />
        </div>
        <div className={`${isFormTooTall ? 'flex flex-col fixed bottom-0 bg-white pb-8 gap-4' : 'block mt-4'}`}>
          {isFormTooTall && <div className="bg-border-gray h-0.5" />}
          <CommonButton onClick={onNext}>{t('continue')}</CommonButton>
        </div>
      </div>
    </>
  );
};
